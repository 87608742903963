import { Component, OnInit } from '@angular/core';
import { ReportesService } from 'src/app/services/reportes.service';

@Component({
  selector: 'app-encuestas-web',
  templateUrl: './encuestas-web.component.html',
  styleUrls: ['./encuestas-web.component.css']
})
export class EncuestasWebComponent implements OnInit {
  clientesDatos ;
  listOfDisplayData = [];
  datosEncuesta= [];
  //test estructura
  expand : boolean;

  constructor(private clientes: ReportesService) { 
    this.expand = false;
  }

  ngOnInit(): void {
    this.expand = false;
    this.mostrarEncuestas();
  }

  mostrarEncuestas() {
    this.clientes.getReporteEncuestaWeb().subscribe( data => {
      this.clientesDatos = data[0].encuesta;
      console.log(this.clientesDatos);
      this.listOfDisplayData = this.clientesDatos;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerProductosNvo(index: number) {
    console.log(index);
    this.datosEncuesta = this.clientesDatos[index]['usuarios']['preguntas_repuestas'];
    
  }
}
