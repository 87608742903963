<nz-row>
    <nz-row>
        <h2 class="report-title"> Bitácora </h2>
    </nz-row>
    <nz-row style="overflow-x:auto;">
        <nz-table #nestedTable [nzData]="bitacoradata" [nzPageSize]="500" nzTableLayout="fixed" id="excel-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Tabla</th>
                    <th>Cambio en</th>
                    <th>Acción</th>
                    <th>Valor Acción</th>
                    <th>ID Usuario</th>
                    <th>Usuario</th>
                </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-data let-index="index" [ngForOf]="nestedTable.data" >
                <tr>
                  <td>{{ data.id_bitacora }}</td>
                  <td>{{ data.fecha }}</td>
                  <td>{{ data.tabla }}</td>
                  <td>{{ data.columna }}</td>
                  <td>{{ data.tipo_operacion }}</td>
                  <td>
                      <div *ngIf="data.datos.message">
                        {{ data.datos.message }}
                      </div>
                      <div *ngIf="data.datos.estado">
                        Estado : {{ data.datos.estado }} 
                      </div>
                      <div *ngIf="data.datos.id_estado_delivery">
                        Estado Delivery : {{ data.datos.id_estado_delivery }} 
                      </div>
                      <div *ngIf="data.datos.id_contacto">
                        ID Contacto : {{ data.datos.id_contacto }} 
                      </div>
                      <div *ngIf="data.datos.nro_transaccion">
                        Nro.Transac. : {{ data.datos.nro_transaccion }} 
                      </div>
                      
                  </td>
                  <td>{{ data.id_usuario }}</td>
                  <td>{{ data.nombre }} {{ data.apellidos }}</td>
                </tr>
                </ng-template>
            </tbody>
          </nz-table>
    </nz-row>
</nz-row>
