import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {
  

  /*name of the excel-file which will be downloaded. */ 
  reporteEncuestas= 'Clientes-Kaizen.xlsx'; 
  listOfDisplayData:any=[];
  dataClientes;
  /* Info to Map */
  latitude: number=0;
  longitude: number=0;
  zoom: number=0;
  address: string="";
  private geoCoder:any;
  /* Modal de mapa */
  isVisible = false;

  @ViewChild('search') public searchElementRef!: ElementRef;
  
  nombres:any;
  apellidos:any;

  pageIndex:any;
  total:any;
  pageSize:any;
  pageSizeOptions:any;

  constructor(private clientes: ClientesService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { 
    //this.listarCliente();
    this.pageIndex = 1;
    this.pageSize = 10;

  }

  listarCliente() {
    this.clientes.obtenerclientesTeros().subscribe( data => {
      this.dataClientes = data;
      this.total = data.length;
      for (let i = 0; i < data.length; i++) {
        this.dataClientes = data;
        this.listOfDisplayData.push(
          {
            edit: false,
            data: data[i]
          }
        );

      }
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  ngOnInit(): void {
    this.clientes.obtenerclientesTeros().subscribe( data => {
      this.dataClientes = data;
      for (let i = 0; i < data.length; i++) {
        this.listOfDisplayData.push(
          {
            edit: false,
            data: data[i]
          }
        );

      }
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.reporteEncuestas);
			
    }

    startEdit(id: string): void {
      this.listOfDisplayData[id].edit = true;
      this.nombres = this.listOfDisplayData[id].data.nombre_completo;
      this.apellidos = this.listOfDisplayData[id].data.nombre_completo;
    }

    canceltEdit(id: string): void {
      this.listOfDisplayData[id].edit = false;
    }
    
    abrirMapa(id:any):void {
      this.isVisible = true;
    }
    actualizar(i, ci,completo,telefono,correo,direccion): void {
      if((completo!=""||completo==null) && correo!="" && direccion!="" && this.latitude !=null) {
        let datos = {};
        datos = {
          ci_nit:ci,
          nombre_completo:completo,
          //nombre:this.nombres,
          //apellidos:this.apellidos,
          direccion:direccion,
          email:correo,
          telefono:telefono,
          latitud:this.latitude,
          longitud:this.longitude,
          _method:'PUT'
          }
  
          this.clientes.actualizarClientesTeros(datos).subscribe( result => {
            if (result!=null) {
              alert("Cliente actualizado");
              this.canceltEdit(i);
              this.ngOnInit();
            } else {
              alert("No se pudo actualizar, intente más tarde.");
            }
          }, error => alert("No se pudo actualizar.")
          );
  
      } else {
        alert("ERROR!!, Debe registrar todos los campos e indicar la dirección con el ping del mapa");
      }
    }
  
    handleOk(): void {
      console.log('Button ok clicked!');
      
      this.isVisible = false;
    }
  
    handleCancel(): void {
      console.log('Button cancel clicked!');
      this.isVisible = false;
    }

    ngAfterViewInit() {
      //Datos del mapa
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
  
        /*let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["address"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: any = autocomplete.getPlace();
  
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;
          });
        });*/
      });
  
    }

     // Get Current Location Coordinates
    setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 15;
          this.getAddress(this.latitude, this.longitude);
        });
      }
    }

    markerDragEnd($event: any) {
        //console.log($event);
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
    }

    getAddress(latitude:any, longitude:any) {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: string | any[], status: string) => {      
        if (status === 'OK') {
          if (results) {
            //console.log(results);
            let indice = results.length > 9 ? 3 : 2;
            this.zoom = 12;
            this.address = results[indice].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
  
      });
    }

    pageIndexChanged($event: any): void {
      console.log($event);
    }
    pageSizeChanged($event: any): void {
      console.log($event);
    }
}
