import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NzI18nService } from 'ng-zorro-antd';
import { format } from 'date-fns';
import { CitasService } from 'src/app/services/citas.service';

@Component({
  selector: 'app-agendar',
  templateUrl: './agendar.component.html',
  styleUrls: ['./agendar.component.css']
})
export class AgendarComponent implements OnInit {
  validateForm: FormGroup;

  marcasData =[];
  modelosData =[];
  serviciosData =[];
  vehiculoData = [];
  horaData = [];
  clienteData;

  selectmarca;
  input_placa;
  input_nombre;
  input_apellido;
  input_telefono
  selectorigen;
  selectmodelo;
  fecha = null;
  selecthora;
  selectservicio;
  selectkilometraje;
  input_kilometraje;
  input_comentario;

  tallerID;

  /* ciudades, sucursales */
  selectciudades

  isMantenimineto;
  genericoOpc:any;
  marca;
  marcaEnviar;

  constructor(private citasService: CitasService, private router: Router, private i18n: NzI18nService) { 
    this.isMantenimineto = false;
    this.obtenerMarcas();
    this.obtenerServicios();
  }

  ngOnInit(): void {
    this.validateForm = new FormGroup({
      marcaagendar: new FormControl('', [Validators.required]),
      ciudad_cita: new FormControl('', [Validators.required]),
      sucursal_cita: new FormControl( '', [ Validators.required ] ),
      fecha_cita: new FormControl( '', [ Validators.required ] ),
      hora_cita: new FormControl( '', [ Validators.required ] ),
      origen_cita: new FormControl( '', [ Validators.required ] ),
      servicio_cita: new FormControl( '', [ Validators.required ] ),
      placa_cita: new FormControl( '', [ Validators.required ] ),
      kilometraje_cita: new FormControl( '', [ Validators.required ] ),
      kilometraje_abierto: new FormControl( '', [ Validators.required ] ),
      comentario_cita: new FormControl( '', [ Validators.required ] ),
      modelo_comercial: new FormControl(  '', [ Validators.required ] ),
      nombre: new FormControl( '', [Validators.required, Validators.maxLength(38)] ),
      apellido: new FormControl( '', [Validators.required, Validators.maxLength(19)] ),
      telefono: new FormControl( '', [Validators.required, Validators.maxLength(8)] )
    });
  }

  obtenerMarcas() {
    this.citasService.obtenerMarcas().subscribe( data => {
      this.marcasData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerServicios() {
    this.citasService.listarServiciosTaller().subscribe( data => {
      this.serviciosData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerCiudadxTaller(marcaLiteral:any) {
    let marcaCod;
    switch (marcaLiteral) {
        case "L":
            marcaCod = 1;
        break;
        case "T":
            marcaCod = 2;
        break;
        case "Y":
            marcaCod = 5;
        break;
          
        default:
            marcaCod = 0;
        break;
    }
    this.citasService.listarDptosxTaller(marcaCod).subscribe( data => {
        if(data != null) {
            console.log(data);
            this.citasService.talleresxDptoMarca(data[0].id_departamento , marcaCod).subscribe( dataTaller => {
                if(dataTaller != null) {
                  this.tallerID = dataTaller[0].id_taller;                 
                }
                console.log(dataTaller);  
            }, error => {
                console.log(error); // Imprimimos en consola el error
            });
        }
         
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }



  generico(cod) {
    this.genericoOpc = cod;
  }

  buscarvehiculo() {
    this.citasService.buscarVehiculo(this.input_placa).subscribe( result => {
      if (result!=null) {
        console.log(result);
        this.vehiculoData = result;
        this.genericoOpc = 0;
        this.buscarCliente(result.cod_tit, result.cod_marca, result.cod_modelo_com);
        alert("Placa registrada");
      } else {
        this.genericoOpc = 1;
        alert("No se tiene registrada la placa");
      }
    });
  }

  buscarCliente(ci:any, codigomarca:any, modelocomercialCodigo:any): void {
    let marcaCod;
    switch (codigomarca) {
        case "L":
            marcaCod = 1;
        break;
        case "T":
            marcaCod = 2;
        break;
        case "Y":
            marcaCod = 5;
        break;
          
        default:
            marcaCod = 0;
        break;
    }
    //console.log(marcaCod);


    this.obtenerModelos(this.obtenerCodigoMarca(marcaCod));
    //console.log(this.buscarModelosComerciales(this.obtenerCodigoMarca(marcaCod)));
    
    this.citasService.buscarCliente(ci).subscribe( result => {
      if (result!=null) {
        this.clienteData = result;
        this.validateForm.controls.nombre.setValue(this.clienteData.nombre+" "+this.clienteData.apellidos);
        //this.validateForm.controls.apellido.setValue();
        this.validateForm.controls.telefono.setValue(this.clienteData.telefono);
        this.validateForm.controls.modelo_comercial.setValue(modelocomercialCodigo);
        //this.validateForm.controls.generico.setValue(0);
      }else {
        this.clienteData = null;
        //this.errorcliente = true;
        setTimeout(() => {
          //this.errorcliente = false;
        }, 4000);
      }
    });
  }

  obtenerModelos(selectmarcarepuesto) {
    this.marcaEnviar = selectmarcarepuesto;
    this.citasService.listarModelosComerciales(selectmarcarepuesto).subscribe( result => {
      if (result!=null) {
        //console.log(result);
        this.modelosData = result;
        this.buscarCiudades(selectmarcarepuesto);
      }
    });
    this.obtenerCiudadxTaller(selectmarcarepuesto);
    
  }

  setFecha() {
    const fecha = moment(this.fecha).format('YYYY-MM-DD');    
    const hora = moment(this.selecthora, "HH:mm").format('HH:mm');  
    this.citasService.listarHora(this.tallerID,fecha).subscribe( result => {
      if (result!=null) {
        this.horaData = result;
      }
    }); 
  }

  buscarCiudades(idMarca): void {
    this.marca = idMarca;

    /*this.citasService.listarDptosxTaller(idMarca).subscribe( result => {
      if (result!=null) {
        this.ciudadesData = result;
      }
    });*/
    
    switch (idMarca) {
      case 5:
        this.selectkilometraje = [];
        //GUARDAR KILOMETRAJE yamaha
        for(var i=1000;i<=300000;i++)
        {
            const that = this;
            if(that.calcularkilometraje(i,3000)){
                that.selectkilometraje.push(i);
            }  
        }
        break;
      
      default:
        this.selectkilometraje = [];
        //GUARDAR KILOMETRAJE Lexus
        for(var i=1000;i<=300000;i++)
        {
            const that = this;
            if(that.calcularkilometraje(i,5000)){
                that.selectkilometraje.push(i);
            }  
        }
      break;
    }
    //console.log(this.selectkilometraje);
    //this.obtenerModelos(this.obtenerCodigoMarca(idMarca));
  }

  obtenerCodigoMarca(nombreMarca:any){
    
    let marcaCod;
    switch (nombreMarca) {
      case 1:
        marcaCod = "L";
        break;
      case 2:
        marcaCod = "T";
        break;
      case 5:
        marcaCod = "Y";
        break;
    
      default:
        marcaCod =  "";
        break;
    }
    this.marcaEnviar = marcaCod;
    return marcaCod;
  }

  calcularkilometraje(valor, multiple){
    const resto = valor % multiple;
    if(resto==0) {
        return true;
    } else {
        return false;
    }
      
  }

  enviarCita() {
    const datosFormulario = this.validateForm.value;
    let datos = {};
      
    switch (this.isMantenimineto) {
      case true:
        datos = {
          placa: datosFormulario.placa_cita.replace(/[^a-zA-Z0-9]/g, ''),
          id_servicio:datosFormulario.servicio_cita,
          id_horario:datosFormulario.hora_cita,
          //id_sucursal:datosFormulario.sucursal_cita,
          id_taller: this.tallerID,
          fecha_cita:format(datosFormulario.fecha_cita, 'yyyy-MM-dd'),
          procedencia:"app-tss",
          kilometraje:datosFormulario.kilometraje_cita,
          comentario:datosFormulario.comentario_cita,
          cod_marca:this.marcaEnviar,
          modelo:datosFormulario.modelo_comercial,
          generico:this.genericoOpc,
          nombre_completo:datosFormulario.nombre ,
          telefono:datosFormulario.telefono,
          id_origen_cita: datosFormulario.origen_cita
          }
        break;
      case false:
          datos = {
            placa: datosFormulario.placa_cita.replace(/[^a-zA-Z0-9]/g, ''),
              id_servicio:datosFormulario.servicio_cita,
              id_horario:datosFormulario.hora_cita,
              //id_sucursal:datosFormulario.sucursal_cita,
              id_taller: this.tallerID,
              fecha_cita:format(datosFormulario.fecha_cita, 'yyyy-MM-dd'),
              procedencia:"app-tss",
              kilometraje:datosFormulario.kilometraje_abierto.replace(/[^0-9]/g, ''),
              comentario:datosFormulario.comentario_cita,
              cod_marca:this.marcaEnviar,
              modelo:datosFormulario.modelo_comercial,
              generico:this.genericoOpc,
              nombre_completo:datosFormulario.nombre ,
              telefono:datosFormulario.telefono,
              id_origen_cita: datosFormulario.origen_cita
          }
        break;
    
      default:
        break;
    }
         
      console.log(datos);

      this.citasService.guardarCita(datos).subscribe( result => {
          if (result!=null) {
            console.log(result);
            if(result.message) {
              alert("No se pudo agendar "+ result.message);
              switch (result.message) {
                case "Cita ya agendada":
                  alert("Esta placa ya tiene una cita agendada");
                  break;
                case "Cantidad máxima de citas por horario alcanzado":
                  alert("Cantidad máxima de citas por horario alcanzado");
                  break;
              
                case "The given data was invalid.":
                  alert("Presione el botón buscar en la placa");
                  break;

                default:
                  break;
              }
            } else {
              alert("Cita agendada!!!");
              localStorage.setItem('KaizenDashboard', 'mostrarCitas');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
            
          } else {
            alert("No se pudo agendar");
          }
      });
  }

  servicoseleccionado(event) {
    
    let codigoServicio  = event;
    if(codigoServicio == "4") {
      console.log(codigoServicio);
      this.isMantenimineto = true;
    } else {
      this.isMantenimineto = false;
      console.log(codigoServicio);
    }
  }
}
