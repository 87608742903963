import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { EquivalentesService } from '../services/repuestos/equivalentes.service';

@Component({
  selector: 'app-add-equivalencia',
  templateUrl: './add-equivalencia.component.html',
  styleUrls: ['./add-equivalencia.component.css']
})
export class AddEquivalenciaComponent implements OnInit {
  /* Form component */
  validateForm: FormGroup;

  input_equivalente = '';

  listOfOptionnroparte;
  listOfTagOptionsnroparte = [];

  listOfOptionmarca = [];
  listOfTagOptionsmarca = [];

  marcaVehiculosDatos = [];

  constructor(private fb: FormBuilder, private repuestos: EquivalentesService, private message: NzMessageService) { 
    this.validateForm = this.fb.group({
      nroparte: [ null, [ Validators.required ] ],
      marca: [null, [Validators.required]],
      equivalente: [ null, [ Validators.required ] ]
    });

  }

  ngOnInit(): void {

    this.obtenerDataMarcasVehiculos();
    this.obtenerNroParte();
  }

  submitForm(): void {

    const datosFormulario = this.validateForm.value;
    const datos = {
      id_repuesto: datosFormulario.nroparte,
      numero_parte_alternativo: datosFormulario.equivalente,
      id_marca_vehiculo:datosFormulario.marca
    };
    
    console.log(datos);

    this.repuestos.addEquivalente(datos).subscribe( data => {
      this.message.create('sucess', 'Equivalente Guardado!');
    }, error => {
        console.log(error); // Imprimimos en consola el error
        this.message.create('error', 'No se pudo guardar!');
    });
    
  }

  obtenerNroParte() {
    this.repuestos.getNroParte().subscribe( data => {
      this.listOfOptionnroparte = data;
      //console.log(this.listOfOption);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerDataMarcasVehiculos() {
    this.repuestos.getMarcasVehiculos().subscribe( data => {
      this.listOfOptionmarca = data;
      console.log(this.listOfOptionmarca);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
}
