import { Component, OnInit } from '@angular/core';
import { BolsaTrabajoService } from '../services/bolsa-trabajo.service';

@Component({
  selector: 'app-bolsa-trabajo-ver-ofertas',
  templateUrl: './bolsa-trabajo-ver-ofertas.component.html',
  styleUrls: ['./bolsa-trabajo-ver-ofertas.component.css']
})
export class BolsaTrabajoVerOfertasComponent implements OnInit {
  postulacionesData;
  listOfDisplayData;
  
  constructor(private bolsaService: BolsaTrabajoService) { }

  ngOnInit(): void {
    this.obtenerOfertas();
  }

  obtenerOfertas() {
    this.bolsaService.obtenerOfertas().subscribe( data => {
      this.postulacionesData = data;
      this.listOfDisplayData = this.postulacionesData;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
  /* To copy any Text */
  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = "https://www.kaizenmotors.com/bolsa-trabajo/"+val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
  }
}
