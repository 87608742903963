import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { RepuestosService } from '../services/repuestos.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-repuestos',
  templateUrl: './repuestos.component.html',
  styleUrls: ['./repuestos.component.css']
})
export class RepuestosComponent implements OnInit {

  clientesDatos ;
  marcas;
  repuestosDatos;

  /*name of the excel-file which will be downloaded. */ 
  reporteEncuestas= 'Compras-Kaizen.xlsx'; 
  filtroFecha;
  filtroCI;
  valueCI: string;
  selectedValue = null;
  configPage: any;
  totalRepuestos;
  cantidadItems = '12';

  constructor(private repuestos: RepuestosService) {
      this.filtroFecha = false;
      this.filtroCI = false;
      const valueKey = "Todas";

      let that = this;
      this.repuestos.getFiltrosMarca().subscribe( data => {
        this.repuestosDatos = data.datos;
        that.totalRepuestos = data.paginacion.total;
        //console.log(this.repuestosDatos);
        //setTimeout(()=> console.log(this.repuestosDatos), 500);
      }, error => {
          console.log(error); // Imprimimos en consola el error
      });

      console.log(this.totalRepuestos);
      this.configPage = {
        itemsPerPage: this.totalRepuestos,
        currentPage: 1,
        totalItems: this.totalRepuestos
      };
      this.mostrarMarcas();
  }

  verFiltroFecha() {
    this.filtroFecha = true;
    this.filtroCI = false;
  }

  verFiltroCI() {
    this.filtroFecha = false;
    this.filtroCI = true;
  }

  ngOnInit(): void {
      
  }

  mostrarMarcas() {
    this.repuestos.getMarcas().subscribe( data => {
      this.marcas = data;
      console.log(this.marcas);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  marca(e, key) {
    key = key.toLowerCase();
    console.log(key);
    this.repuestos.getFiltrosMarca().subscribe( data => {
      this.repuestosDatos = data.datos;
      this.totalRepuestos = data.paginacion.total;
      console.log(this.repuestosDatos);
      //setTimeout(()=> console.log(this.repuestosDatos), 500);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  pageChanged(event){
    this.configPage.currentPage = event;
    //this.mostrarRepuestos();
    this.repuestos.getFiltrosMarca().subscribe( data => {
      this.repuestosDatos = data.datos;
      this.totalRepuestos = data.paginacion.total;
      console.log(this.repuestosDatos);
      //setTimeout(()=> console.log(this.repuestosDatos), 500);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
    console.log(event);
  }
  

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.reporteEncuestas);
    
  }

  cantidadItemsMetod (e, cantidad) {
    console.log(cantidad);
    this.configPage.itemsPerPage = cantidad;
    this.repuestos.getFiltrosMarca().subscribe( data => {
      this.repuestosDatos = data.datos;
      console.log(this.repuestosDatos);
      //setTimeout(()=> console.log(this.repuestosDatos), 500);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
}
