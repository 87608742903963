import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  token: string;

  constructor(private http: HttpClient) {
      if (localStorage.getItem('userKaizenDashboard')) {
        this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
      }
  }

  obtenerTransaccionesRepeustosServiciosQr() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })            
    };
    const url = `${environment.base_url}/teros/transaccion/qr`;
    return this.http.get<any>(url, httpOptions);
  }

  obtenerHashQr(data) {
    const datos = {
      orden_trabajo: data
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })            
    };
    const url = `${environment.base_url}/pago-servicio/qr`;
    return this.http.post<any>(url,datos, httpOptions);
  }

  obtenerHashQrRepuesto(data) {
    const datos = {
      nro_transaccion: data
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })            
    };
    const url = `${environment.base_url}/payments/qrs/transaction`;
    return this.http.post<any>(url,datos, httpOptions);
  }

  // Servicio que obtiene las marcas de los repuestos
  send() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto/marca`;
    return this.http.get<any>(url, httpOptions);
  }
}
