import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitasService {

  token: string;
  
  constructor(private http: HttpClient) { 
    if (localStorage.getItem('userKaizenDashboard')) {
      this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
    }
  }

    // Servicio que obtiene las marcas de los repuestos
    obtenerCitas(datestart, dateend) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cita?fecha_ini=${datestart}&fecha_fin=${dateend}`;
      return this.http.get<any>(url, httpOptions);
    }
    // Servicio que obtiene las marcas de los repuestos
    obtenerCitasChaperio(datestart, dateend) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cita?fecha_ini=${datestart}&fecha_fin=${dateend}&chaperio=1`;
      return this.http.get<any>(url, httpOptions);
    }

    // obtenerMarcas
    obtenerMarcas() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/vehiculo/marca?cita=true`;
      return this.http.get<any>(url, httpOptions);
    }

    // Buscar vehiculo por placa
    buscarVehiculo(placa:any) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/teros/vehiculo/${placa}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Buscar vehiculo por placa
    buscarCliente(ci:any) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/teros/cliente/${ci}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Buscar vehiculo por placa
    origenCita() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cita/origen`;
      return this.http.get<any>(url, httpOptions);
    }

    // Listar modelos comerciales por marca
    listarModelosComerciales(codliteralmarca:any) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/teros/vehiculo/modelo/comercial?cod_marca=${codliteralmarca}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Listar modelos comerciales por marca
    listarDptosxTaller(codNummarca:any) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/departamento/sucursal/marca/${codNummarca}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Listar talleres
    talleresxDptoMarca(dpto:any, marca:any) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/taller/departamento/${dpto}/marca/${marca}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Listar Hora
    listarHora(idTaller:any, fecha:any) {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/taller/horario?id_taller=${idTaller}&fecha=${fecha}`;
      return this.http.get<any>(url, httpOptions);
    }
        // Listar servicios
    listarServiciosTaller() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/taller/servicios`;
      return this.http.get<any>(url, httpOptions);
    }

    // Guardar citas
    guardarCita(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cita`;
      return this.http.post<any>(url,data, httpOptions);
    }

    // actualizar estados
    actualizar(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cita`;
      return this.http.put<any>(url,data, httpOptions);
    }
}
