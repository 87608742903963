import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'; 
import differenceInCalendarDays from 'date-fns/differenceInBusinessDays';
import * as moment from 'moment';
import { TransaccionesService } from 'src/app/services/transacciones.service';
import { Key } from 'protractor';

interface DetalleTransaccion {
  nro_transaccion: string;
  nro_proforma: string;
  id_compra: number;
  fecha_compra: string;
  id_usuario: number;
  nombre: string;
  apellidos: string;
  total_compra: number;
  metodo_pago: string;
  estado: string;
  productos : [];
}

@Component({
  selector: 'app-listado-transacciones',
  templateUrl: './listado-transacciones.component.html',
  styleUrls: ['./listado-transacciones.component.css']
})
export class ListadoTransaccionesComponent implements OnInit {
  clientesDatos ;

  /*name of the excel-file which will be downloaded. */ 
  reporteEncuestas= 'Compras-Kaizen.xlsx'; 
  today = new Date();   
  fechaInicial;
  fechaFinal;
  horas = [];
  horaSelected;
  filtroFecha;
  filtroCI;
  valueCI: string;

  //test estructura
  expand : boolean;

  datosServicio = [];
  mostrarTransacciones = [];
  datosProductos = [];

  //filtros
  sortValue: 'ascend' | 'descend' | null = null;
  listOfFilterEstado = [{ text: 'Pendiente', value: 'Pendiente' }, { text: 'Pagado', value: 'Pagado' }];
  searchName: string[] = [];
  sortClientID: string | null = null;
  searchValue = '';
  listOfSearchTransacciones: string[] = [];
  selectEstadoTransaccion = null;
  selectEstadoDelivery = null;

  constructor(private transacciones: TransaccionesService) {
      this.filtroFecha = false;
      this.filtroCI = false;
      this.expand = false;
  }

  ngOnInit(): void {
    this.mostrarDatosServicio();

    this.expand = false;

  }

  obtenerProductos(index: number) {
    this.datosProductos = this.datosServicio[index]['productos'];
    console.log(this.datosProductos);
  }

  verFiltroFecha() {
    this.filtroFecha = true;
    this.filtroCI = false;
  }

  verFiltroCI() {
    this.filtroFecha = false;
    this.filtroCI = true;
  }

 
  mostrarDatosServicio() {
    this.transacciones.obtenertransacciones().subscribe( data => {
      this.datosServicio = data;
      this.mostrarTransacciones = this.datosServicio;
     
      console.log(this.datosServicio);
     
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  reset(): void {
    this.searchValue = '';
    this.searchTransaccion();
  }

  sort(sortName: string, value: string): void {
    this.sortClientID = sortName;
    //this.sortValue = value;
    this.searchTransaccion();
  }

  filterAddressChange(value: string[]): void {
    this.listOfSearchTransacciones = value;
    this.searchTransaccion();
  }

  searchTransaccion(): void {     
    const filterFunc = (item: DetalleTransaccion) => {
      return (
        (this.listOfSearchTransacciones.length
          ? this.listOfSearchTransacciones.some(address => item.estado.indexOf(address) !== -1)
          : true) && item.nro_transaccion.indexOf(this.searchValue) !== -1
      );
    };
    const data = this.datosServicio.filter((item: DetalleTransaccion) => filterFunc(item));
    this.mostrarTransacciones = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortClientID!] > b[this.sortClientID!]
          ? 1
          : -1
        : b[this.sortClientID!] > a[this.sortClientID!]
        ? 1
        : -1
    );
  }

  search(searchName: string[]): void {
    this.searchName = searchName;
    const filterFunc = (item: DetalleTransaccion) => {
      return this.searchName.length ? this.searchName.some(name => item.nro_transaccion.indexOf(name) !== -1) : true;
    };
    const listOfData = this.datosServicio.filter(item => filterFunc(item));
    this.mostrarTransacciones = listOfData.sort((a, b) =>
      this.sortValue === 'ascend' ? (a.age > b.age ? 1 : -1) : b.age > a.age ? 1 : -1
    );
  }

  verDetalle(idCompra) {
      console.log(idCompra);
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.reporteEncuestas);
    
  }

  setFechaInicial() {
    const fecha = moment(this.fechaInicial).format('YYYY-MM-DD');    
    const hora = moment(this.horaSelected, "HH:mm").format('HH:mm');    
    this.fechaInicial = moment(fecha).format('YYYY-MM-DD');
    console.log("FI:  " + this.fechaInicial);    
  }

  setFechaFinal() {
    const fecha = moment(this.fechaFinal).format('YYYY-MM-DD');    
    const hora = moment(this.horaSelected, "HH:mm").format('HH:mm');    
    this.fechaFinal = moment(fecha).format('YYYY-MM-DD');    
    console.log("FF:  " + this.fechaFinal);    
  }

  mostrarFechaHora(params) {
    if (params) {
      return moment(this.fechaInicial).format('DD-MM-YYYY');
    } else {
      return moment(this.fechaInicial).format('HH:mm');
    }
  }

  estadoTransaccion(e, key, nro_transaccion) {
    const datos = {
      nro_transaccion: nro_transaccion,
      estado: key,
      _method : 'PUT'
    };

    this.transacciones.editarEstadotransacciones(datos).subscribe( data => {
        console.log(data);   
        this.mostrarDatosServicio();  
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
    console.log(key + "nro> " + nro_transaccion );
  }

  estadoDelivery(e, key, nro_transaccion) {
    const datos = {
      nro_transaccion: nro_transaccion,
      estado: key,
      _method : 'PUT'
    };

    this.transacciones.editarEstadoDelivery(datos).subscribe( data => {
        console.log(data);   
        this.mostrarDatosServicio();  
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
    console.log(key + "nro> " + nro_transaccion );
  }
}
