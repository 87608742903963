<nz-row  style="overflow-x:auto;">
    <nz-col>
        <nz-table #nestedTable [nzData]="listOfDisplayData" nzTableLayout="fixed" id="excel-table" class="table-clientes">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre </th>
                <th>Apellidos</th>
                <th>¿En general, ¿cuál fue tu grado de satisfacción con la compra en nuestra tienda online?</th>
                <th>¿Dónde te encontraste con nuestra tienda online por primera vez?</th>
                <th>¿Qué es lo que podemos hacer para mejorar tu satisfacción con la compra en nuestra tienda online?</th>
              </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-data let-index="index" [ngForOf]="nestedTable.data" >
                    <tr>
                        <td>{{ (index+1) }}</td>
                        <td>{{ data.usuarios.nombre }}</td>
                        <td>{{ data.usuarios.apellidos }}</td>
                        <td>{{ data.usuarios.preguntas_repuestas[1].respuesta }}</td>
                        <td>{{ data.usuarios.preguntas_repuestas[0].respuesta }}</td>
                        <td>{{ data.usuarios.preguntas_repuestas[2].respuesta }}</td>
                    </tr>
                </ng-template>
            </tbody>
        </nz-table>
    </nz-col>
</nz-row>
