import { Component, OnInit } from '@angular/core';
import { BolsaTrabajoService } from '../services/bolsa-trabajo.service';

@Component({
  selector: 'app-bolsa-trabajo-postulaciones',
  templateUrl: './bolsa-trabajo-postulaciones.component.html',
  styleUrls: ['./bolsa-trabajo-postulaciones.component.css']
})
export class BolsaTrabajoPostulacionesComponent implements OnInit {
  postulacionesData;
  listOfDisplayData;
  
  constructor(private bolsaService: BolsaTrabajoService) { }

  ngOnInit(): void {
    this.obtenerPostulaciones();
  }

  obtenerPostulaciones() {
    this.bolsaService.obtenerPostulaciones().subscribe( data => {
      this.postulacionesData = data;
      this.listOfDisplayData = this.postulacionesData;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
}
