import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    mostrarClientes;
    mostrarClientesTeros;
    mostrarTransacciones;
    mostrarServicios;
    mostrarRepuestos;
    mostrarAgregarRepuestos;
    mostrarCitas;
    mostrarContacto;
    mostrarPagoServicios;
    mostrarBitacora;
    mostrarSurveyRepuestos;
    mostrarSurveyServicios;
    mostrarReporteEncuestaWeb;
    mostrarReporteEncuestaMostrador;
    mostrarCompatibilidad;
    mostrarEquivalencia;
    mostrarAgendar;
    mostrarAgregarOferta;
    mostrarVerPostulaciones;
    mostrarVerOfertas;

    mostrarB2BlistadoClientes;
    mostrarB2BregistroClientes;
    mostrarB2BlistadoTransacciones;
    logueado: boolean;
    public userData: any;
    public userJson:any;

    isCollapsed = false;

    constructor(private router: Router) { 
      this.mostrarClientes = true;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar=false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    ngOnInit(): void {
        this.userData = localStorage.getItem('userKaizenDashboard');
        this.userJson = JSON.parse(this.userData);

        if(this.userJson != null){
            this.logueado = true;
            let urlData = localStorage.getItem('KaizenDashboard');
            if(urlData == 'mostrarCitas'){
              this.verCitasProgramadas();
              localStorage.setItem('KaizenDashboard', 'mostrarClientes');
            } else {

            }
        } else {
          this.logueado = false;
        }


    }

    logout(){
      this.userData = localStorage.getItem('userKaizenDashboard');
      this.userJson = JSON.parse(this.userData);
      console.log("User: " + this.userJson);
  
      if(this.userJson != null){
        localStorage.removeItem('userKaizenDashboard');
        this.router.navigate(['/login']);      
        //this.location.back();
      } else {
        this.logueado = true;
      }
    }

    toggleCollapsed(): void {
      this.isCollapsed = !this.isCollapsed;
    }

    verClientes() {
      this.mostrarClientes = true;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verClientesTeros() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = true;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verTransacciones() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = true;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verRepuestos() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = true;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verAgregarRepuestos() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = true;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verCitasProgramadas() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = true;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    
    verCitasPasadas() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = true;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verContactoClientes() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = true;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verPagoServicios() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = true;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verBitacora() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = true;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verServicios() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = true;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verRepuestosMostrador() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = true;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verSurveyRepuestos() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = true;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verSurveyServicios() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = true;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verReporteEncuestaWeb() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = true;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verReporteEncuestaMostrador() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = true;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verAgregarCompatibilidad() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = true;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verAgregarEquivalencia() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = true;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verAgregarCita() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = true;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verAgregarOferta() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = true;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verPostulaciones() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=true;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verOfertas() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=true;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }

    verB2BlistadoClientes() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=true;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=false;
    }
    verB2BlistadoTransacciones() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=true;
      this.mostrarB2BregistroClientes=false;
    }
    verB2BregistroClientes() {
      this.mostrarClientes = false;
      this.mostrarClientesTeros = false;
      this.mostrarTransacciones = false;
      this.mostrarServicios = false;
      this.mostrarRepuestos = false;
      this.mostrarAgregarRepuestos = false;
      this.mostrarCitas = false;
      this.mostrarContacto = false;
      this.mostrarPagoServicios = false;
      this.mostrarBitacora = false;
      this.mostrarSurveyRepuestos = false;
      this.mostrarSurveyServicios = false;
      this.mostrarReporteEncuestaWeb = false;
      this.mostrarReporteEncuestaMostrador = false;
      this.mostrarCompatibilidad = false;
      this.mostrarEquivalencia = false;
      this.mostrarAgendar = false;
      this.mostrarAgregarOferta = false;
      this.mostrarVerPostulaciones=false;
      this.mostrarVerOfertas=false;
      this.mostrarB2BlistadoClientes=false;
      this.mostrarB2BlistadoTransacciones=false;
      this.mostrarB2BregistroClientes=true;
    }
}
