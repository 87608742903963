import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransaccionesService {

    token: string;
    constructor(private http: HttpClient) {
      if (localStorage.getItem('userKaizenDashboard')) {
        this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
      }
    }

    // Servicio que obtiene las marcas de los repuestos
    obtenertransacciones() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/transacciones`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las marcas de los repuestos
    obtenertransaccionesServicio() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/pago-servicio/qr`;
      return this.http.get<any>(url, httpOptions);
    }

    // Edicion del estado de transacciones
    editarEstadotransacciones(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/transaccion`;
      return this.http.post<any>(url,data, httpOptions);
    }

    // Edicion del estado de deliverys
    editarEstadoDelivery(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/transaccion/delivery`;
      return this.http.post<any>(url,data, httpOptions);
    }
}
