import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiciosService } from '../services/servicios.service';
import { da } from 'date-fns/locale';

@Component({
  selector: 'app-pago-servicios',
  templateUrl: './pago-servicios.component.html',
  styleUrls: ['./pago-servicios.component.css']
})
export class PagoServiciosComponent implements OnInit {
  validateForm!: FormGroup;
  validateFormOrden!: FormGroup;
  placaData;
  qr;
  datosServicio;
  loading: boolean = false;
  verQr = false;

  ordenData;
  qrRepuesto;

  nroCliente;
  tipoTransaccion;

  datostransacciones:any[]=[];

  constructor(private fb: FormBuilder, private servicio: ServiciosService) {
    this.qr = "";
    this.tipoTransaccion = "NN";
    this.mostrarListadoTransacciones();
   }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      placa: [null, [Validators.required]],
      remember: [true]
    });
    this.validateFormOrden = this.fb.group({
      orden: [null, [Validators.required]],
      remember: [true]
    });
  }

  submitForm(): void {
    
  }
  submitFormOrden(): void {
    
  }

  mostrarListadoTransacciones() {
    this.servicio.obtenerTransaccionesRepeustosServiciosQr().subscribe( data => {
      this.datostransacciones = data;
      console.log(this.datostransacciones);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  generarQr(placa) {
    console.log(placa);
    this.tipoTransaccion = "Servicio";
    this.verQr = true;
    this.loading = true;

    this.servicio.obtenerHashQr(placa).subscribe( data => {
      this.loading = false;
      this.datosServicio = data;
      this.qr=data.qr;
      console.log(data);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  generarQrOrden(orden) {
    console.log(orden);
    this.tipoTransaccion = "Repuesto";
    this.verQr = true;
    this.loading = true;

    this.servicio.obtenerHashQrRepuesto(orden).subscribe( data => {
      this.loading = false;
      this.datosServicio = data;
      this.qr=data.qr;
      console.log(data);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
}
