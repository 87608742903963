import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-b2b-listado-transacciones',
  templateUrl: './b2b-listado-transacciones.component.html',
  styleUrls: ['./b2b-listado-transacciones.component.css']
})
export class B2bListadoTransaccionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
