<nz-row class="clientes">
    <nz-row>
        <div class="btn-download">
            <a style="cursor: pointer" (click)="exportexcel()">
                <i nz-icon nzType="download" nzTheme="outline"></i> Descargar Reporte
            </a>
            <h2 class="report-title"> Listado de Clientes </h2>
        </div>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="4" nzOffset="6">
          <div [nzDropdownMenu]="menu"
          nz-dropdown
          #dropdown="nzDropdown"
          [class.ant-table-filter-open]="dropdown.nzVisible"
          nzTrigger="click"
          nzPlacement="bottomRight"
          [nzClickHide]="false"
          nzTableFilter class="control-filter">
              Buscar por CI 
          </div>
          
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
          <a class="control-filter">
            Buscar por Nombre
          </a>
      </nz-col>
    </nz-row>
    <!--Espacios de Filtros -->
    <nz-row class="block-filter" *ngIf="filtroFecha">
      <h4 class="filter-title">Filtrar por Fecha de Nacimiento</h4>
      <nz-col nzSpan="6" nzOffset="6">
        <span class="filter-lbl">Fecha de Nacimiento :</span>
        <nz-date-picker name="fechaInicial" nzDisabledTime [(ngModel)]="fechaInicial" (ngModelChange)="setFechaInicial()"></nz-date-picker>
      </nz-col>
      <!--<nz-col nzSpan="6" nzOffset="1">
        <span class="filter-lbl">Fecha Final :</span>
        <nz-date-picker name="fechaFinal" nzDisabledTime [nzAllowClear]="false"	 [(ngModel)]="fechaFinal" (ngModelChange)="setFechaFinal()"></nz-date-picker>
      </nz-col>-->
      <nz-col nzSpan="4" nzOffset="1">
        <a class="btn-filter">Filtrar</a>
      </nz-col>
    </nz-row>

    <!-- FILTRAR POR CI -->

    <nz-row class="block-filter" *ngIf="filtroCI">
      <h4 class="filter-title">Filtrar por CI</h4>
      <nz-col nzSpan="6" nzOffset="6">
        <span class="filter-lbl">Cédula de Identidad :</span>
        <input nz-input placeholder="Nro. de CI" [(ngModel)]="valueCI" />
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
        <a class="btn-filter">Filtrar</a>
      </nz-col>
    </nz-row>

    <nz-row style="overflow-x:auto;">
        <nz-table #basicTable [nzData]="listOfDisplayData" nzTableLayout="fixed" id="excel-table" class="table-clientes">
            <thead>
              <tr>
                <th>ID</th>
                <th>Fecha Reg</th>
                <th>Nombres </th>
                <th>Apellidos</th>
                <th>CI</th>
                <th>Fecha de Nacimiento</th>
                <th nzShowFilter [nzFilters]="listOfFilterGenero" (nzFilterChange)="filterAddressChange($event)">Genero
                </th>
                <th>Teléfono</th>
                <!--<th>Ciudad</th>-->
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of listOfDisplayData; let i=index">
                    <td>{{ (i+1) }}</td>
                    <td>{{ data.fecha_creacion }}</td>
                    <td>{{ data.nombre }}</td>
                    <td>{{ data.apellidos }}</td>
                    <td>{{ data.nro_documento }}</td>
                    <td>{{ data.fecha_nacimiento }}</td>
                    <td>{{ data.genero }}</td>
                    <td>{{ data.telefono }}</td>
                    <!--<td>{{ data.ciudad }}</td>-->
                </tr>
            </tbody>
        </nz-table>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <div class="search-box">
            <input type="text" nz-input placeholder="Buscar por CI" [(ngModel)]="searchValue" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
              Buscar
            </button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button>
          </div>
        </nz-dropdown-menu>
    </nz-row>  
</nz-row>