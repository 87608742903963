import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RepuestosService {

  token: string;
  constructor(private http: HttpClient) { 
    if (localStorage.getItem('userKaizenDashboard')) {
      this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
    }
  }

  // Servicio que obtiene las marcas de los repuestos
  getMarcas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto/marca`;
    return this.http.get<any>(url, httpOptions);
  }

  // Obtener filtros de una marca
  getFiltrosMarca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto`;
    return this.http.get<any>(url, httpOptions);
  }
}