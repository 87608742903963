<nz-row>
    <h2 class="color-kaizen title-contact">Pago por Mostrador</h2>
    <!--<nz-row>
        <nz-col [nzXs]="{span:24, offset:0}" [nzMd]="{ span: 10, offset: 2 }" [nzLg]="{ span: 10, offset: 2 }">
            <h4>Servicios</h4>
            <form nz-form [nzLayout]="'inline'" [formGroup]="validateForm" (ngSubmit)="submitForm()">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Ingrese el Nro de Orden de Trabajo (OT)">
                    <nz-input-group>
                      <input formControlName="placa" [(ngModel)]="placaData"  nz-input placeholder="Nro de OT" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control>
                    <a (click)="generarQr(placaData)" class="buscar">Buscar..</a>
                  </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
        <nz-col [nzXs]="{span:24, offset:0}" [nzMd]="{ span: 10, offset: 1 }" [nzLg]="{ span: 10, offset: 1 }">
            <h4>Repuestos</h4>
            <form nz-form [nzLayout]="'inline'" [formGroup]="validateFormOrden" (ngSubmit)="submitFormOrden()">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Ingrese el Nro de Orden">
                    <nz-input-group>
                      <input formControlName="orden" [(ngModel)]="ordenData"  nz-input placeholder="Nro de Transaccion" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control>
                    <a (click)="generarQrOrden(ordenData)" class="buscar">Buscar..</a>
                  </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>-->
    <nz-row *ngIf="!verQr">
      <nz-table #nzTable [nzData]="datostransacciones">
        <thead>
          <tr>
            <th>Acciones</th>
            <th nzCustomFilter>
              Cliente
              <i
                nz-th-extra
                class="ant-table-filter-icon"
                nz-icon
                nz-dropdown
                #dropdown="nzDropdown"
                nzType="search"
                [class.ant-table-filter-open]="dropdown.nzVisible"
                nzTrigger="click"
                nzPlacement="bottomRight"
                [nzClickHide]="false"
                nzTableFilter
              ></i>
            </th>
            <th>Nro Documento</th>
            <th>Nro Transacción</th>
            <th>Tipo de Transacción</th>
            <th>Cod Titular</th>
            <th>Importe</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of nzTable.data">
            <td><a (click)="generarQrOrden(data.nro_trans)" style="font-size: 25pt;"><i nz-icon nzType="qrcode" nzTheme="outline"></i></a></td>
            <td>{{ data.nom_tit }}</td>
            <td>{{ data.nro_doc }}</td>
            <td>{{ data.nro_trans }}</td>
            <td>{{ data.tipo_transaccion }}</td>
            <td>{{ data.cod_tit }}</td>
            <td>{{ data.importe }}</td>
            <td>{{ data.fecha | date: 'dd/MM/yyyy HH:mm' }}</td>
          </tr>
        </tbody>
      </nz-table>
      <!--<nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </nz-dropdown-menu>-->
    </nz-row>
    <nz-row *ngIf="verQr">
        <div class="pay-loading" *ngIf="loading">
            <button nz-button nzLoading nzShape="circle" style="color: red;"></button>
            <img src="../../assets/images/logos/logo-kaizen-motors-rojo.svg" alt="kaizen"/>
        </div>
        <div *ngIf="!loading" class="cobro">
            <h3 class="color-kaizen cash-title">COBRO Qr</h3>
            <p class="info">Scannea con la app de tu baco de preferencia el código Qr</p>
            <a href="{{qr}}" download="kaizenmotors" style="color: red;font-size: 12px;font-weight: 700;display: block;">
              <img src="../../assets/images/download.svg" class="icon-down_qr"/>
              Descargar
            </a>
            <img src={{qr}} width="250px" height="250px" download/>
            
            <div class="datos">
                Servicio : <span>{{datosServicio.descripcion}}</span>
            </div>
            <div class="datos">
                Total Cobrar : <span>{{datosServicio.monto_total}} Bs.</span>
            </div>

            <div class="title">
                Ingrese el número de celular del cliente
            </div>
            <div class="section-share">
              <input [(ngModel)]="nroCliente"  placeholder="7777777" class="input-phoneCliente"/>
              <a href="https://api.whatsapp.com/send?phone=+591{{nroCliente}}&text=Estimad@%20cliente,%20le%20proporcionamos%20su%20código%20Qr%20para%20que%20pueda%20realizar%20el%20pago%20de%20su%20{{tipoTransaccion}}" target="_blank" class="btn-contact">
                  <img src="../../assets/images/logos/whatsapp.svg" class="img-spec-avance"/>
                  <span class="txt-spec-avance">Compartir</span>
              </a>
        
            </div>
            
        </div>   
    </nz-row>
    
    
</nz-row>
