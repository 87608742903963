<nz-row class="clientes">
    <nz-row>
        <div class="btn-download">
            <a style="cursor: pointer" (click)="exportexcel()">
                <i nz-icon nzType="download" nzTheme="outline"></i> Descargar Reporte
            </a>
            <h2 class="report-title"> Listado de Clientes </h2>
        </div>
    </nz-row>
    
    <nz-row style="overflow-x:auto;">
        <nz-table #editRowTable nzBordered  [nzData]="listOfDisplayData.data" nzTableLayout="fixed" id="excel-table" class="table-clientes">
            <thead>
              <tr>
                <th>CI / NIT</th>
                <th>Nombre Completo</th>
                <th>Teléfono </th>
                <th>Correo</th>
                <th>Dirección</th>
                <th>Geolocalización</th>
                <th>Estado </th>
                <!--<th>Ciudad</th>-->
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of listOfDisplayData; let i=index">
                    <td>
                        {{ data.data.ci_nit }}
                    </td>
                    <td>
                        <ng-container *ngIf="!listOfDisplayData[i].edit; else nombreInputTpl">
                            {{ data.data.nombre_completo }}
                        </ng-container>
                        <ng-template #nombreInputTpl>
                            <nz-form-label [nzRequired]="true" class="lbl">Nombre completo</nz-form-label>
                            <input type="text" nz-input [(ngModel)]=" data.data.nombre_completo"/>
                        </ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="!listOfDisplayData[i].edit; else phoneInputTpl">
                            {{ data.data.telefono }}
                        </ng-container>
                        <ng-template #phoneInputTpl>
                            <nz-form-label [nzRequired]="true" class="lbl">Teléfono</nz-form-label>
                            <input type="text" nz-input #telefono [(ngModel)]="data.data.telefono" />
                        </ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="!listOfDisplayData[i].edit; else emailInputTpl">
                            <span class="no-register" *ngIf='data.data.email==""'>No registrado</span>
                            <span *ngIf='data.data.email!==""'>{{ data.data.email }}</span>
                        </ng-container>
                        <ng-template #emailInputTpl>
                            <nz-form-label [nzRequired]="true" class="lbl">Correo electrónico</nz-form-label>
                            <input type="text" nz-input #correo [(ngModel)]="data.data.email" />
                        </ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="!listOfDisplayData[i].edit; else addressInputTpl">
                            <span class="no-register" *ngIf='data.data.direccion==""'>No registrada</span>
                            <span *ngIf='data.data.direccion!==""'>{{ data.data.direccion }}</span>
                        </ng-container>
                        <ng-template #addressInputTpl>
                            <nz-form-label [nzRequired]="true" class="lbl">Dirección</nz-form-label>
                            <input type="text" nz-input #direccion [(ngModel)]="data.data.direccion" />
                        </ng-template>
                        
                    </td>
                    <td>
                        <ng-container *ngIf="!listOfDisplayData[i].edit; else geolocalizacionInputTpl">
                            <span class="no-register" *ngIf="data.data.latitud==null">No registrada</span>
                            <a *ngIf="data.data.latitud" href="https://www.google.com/maps?q={{data.data.latitud}},{{data.data.longitud}}&z=17&hl=es" target="_blank" class="btn-share">Ver mapa</a>
                            <a *ngIf="data.data.latitud" data-action="share/whatsapp/share" target="_blank" class="btn-share" href="https://api.whatsapp.com/send?text=https%3A%2F%2Fwww.google.com%2Fmaps?q={{data.data.latitud}},{{data.data.longitud}}&z=17&hl=es">Compartir Whastapp</a>
                        </ng-container>
                        <ng-template #geolocalizacionInputTpl>
                            <a class="link-map" (click)="abrirMapa(listOfDisplayData[i])">Abrir mapa</a>
                        </ng-template>
                        
                    </td>
                    
                    <td>
                        <div *ngIf="data.data.estado==0">
                            <ng-container *ngIf="!listOfDisplayData[i].edit; else geolocalizacionInputTpl">
                                <a class="btn-up" (click)="startEdit(i)" >Editar</a>
                            </ng-container>
                            <ng-template #geolocalizacionInputTpl>
                                <a class="btn-up" (click)="actualizar(i, data.data.ci_nit, data.data.nombre_completo, data.data.telefono, data.data.email, data.data.direccion)" >Actualizar</a>
                                <a class="btn-cancel" (click)="canceltEdit(i)" >Cancelar</a>
                            </ng-template>
                        </div>
                        <span class="up" *ngIf="data.data.estado==1">{{ data.data.nombre_estado }}</span>
                    </td>
                </tr>
            </tbody>
        </nz-table>
        
        <nz-modal [(nzVisible)]="isVisible" nzTitle="Indica la dirección" (nzOnOk)="handleOk()" (nzOnCancel)="handleCancel()">
            <ng-container>
                <div class="map-contain" style="height: auto; width: auto;">
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"  style="height: 300px;" class="item-form item-rd">
                        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                    </agm-map>
                    {{latitude}}
                    {{longitude}}
                </div>
            </ng-container>
        </nz-modal>
    </nz-row>  
</nz-row>
