<h2 class="color-kaizen title-bolsa">Postulaciones laborales</h2>
<nz-row style="overflow-x:auto;">
    <nz-table #basicTable [nzData]="listOfDisplayData" nzTableLayout="fixed" id="excel-table" class="table-clientes">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha Creación</th>
            <th>Cargo </th>
            <th>Postulante</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>Pretensión salarial</th>
            <th>Curriculum</th>
            <!--<th>Ciudad</th>-->
            
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of listOfDisplayData; let i=index">
                <td>{{ data.id_postulacion }}</td>
                <td>{{ data.fecha_creacion }}</td>
                <td>{{ data.cargo }}</td>
                <td>{{ data.nombre }} {{ data.apellidos }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.telefono }}</td>
                <td>{{ data.pretension_salarial }}</td>
                <td><a [href]="data.curriculum_vitae" target="_blank" class="download">Descargar</a></td>
                <!--<td>{{ data.ciudad }}</td>-->
            </tr>
        </tbody>
    </nz-table>

</nz-row>  