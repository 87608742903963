<nz-row>
    <div nz-col [nzXs]="24" [nzMd]="24" [nzLg]="{span:24, offset:0}">
        <div style="text-align: center;" *ngIf="loading">
            <button nz-button nzLoading nzShape="circle"></button>
            <p style="color: #4b4949; margin-top: 1%; text-align: center;">Solicitando...</p>
          </div>
    </div>
    <nz-col [nzXs]="{span:22, offset:1}" [nzMd]="{span:12, offset:6}" [nzLg]="{span:6, offset:9}" class="login-content" >
        <nz-row class="header-login">
            <div class="logo">
                <a href="/">
                <img src="../../assets/images/logos/logo-kaizen-motors-rojo.svg" class="logo-img"/>
                </a>
            </div>
            <div class="companie-name font-title">Iniciar sesión</div>
        </nz-row>
        <nz-row>
            <nz-col [nzXs]="{span:22, offset:1}" [nzMd]="24" [nzLg]="{span:24, offset:0}">
                <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                    <nz-form-item>
                      <nz-form-control nzErrorTip="Por favor ingrse su usuario!">
                        <nz-input-group nzPrefixIcon="user">
                          <input type="text" nz-input formControlName="userName" [(ngModel)]="mail" placeholder="Usuario" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-control nzErrorTip="Por favor ingrse su contraseña!">
                        <nz-input-group nzPrefixIcon="lock">
                          <input type="password" nz-input formControlName="password" [(ngModel)]="pass" placeholder="Contraseña" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item class="btn-kaizen">
                      <nz-form-control>
                        <button nz-button class="login-form-button" [nzType]="'primary'" (click)="sendLogin()">Ingresar</button>
                      </nz-form-control>
                    </nz-form-item>
                  </form>
            </nz-col>
        </nz-row>
    </nz-col>
</nz-row>
  