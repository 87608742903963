import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  token: string;

  constructor(private http: HttpClient) {
    
    if (localStorage.getItem('userKaizenDashboard')) {
      this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
    }
  }

  getEncuestaRepuestos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/encuesta/1`;
    return this.http.get<any>(url, httpOptions);
  }

  enviarEncuestaRepuestos(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/encuesta`;
    return this.http.post<any>(url, data , httpOptions);
  }
}