<div nz-row class="add-oferta">
    <h2 class="color-kaizen title-bolsa">Nueva oferta laboral</h2>
    <div nz-col [nzXs]="{span:24, offset:0}"  [nzMd]="{ span: 24 }" [nzLg]="{ span: 24 }">
        <form nz-form  nz-row [formGroup]="validateForm"  class="formularioAccount">
            
            <nz-row>
                <!-- Marca -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 10, offset:1 }" [nzLg]="{ span: 10, offset:1 }" class="first-column">
                    <nz-row>
                        <nz-form-item class="items-personalize">
                            <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Nombre del cargo </span></nz-form-label>
                            <nz-form-control class="control-paralelo">
                                <input type="text" nz-input formControlName="cargo" placeholder="Ingrese el cargo" nzPlacement="rightTop" nz-tooltip>
                            </nz-form-control> 
                        </nz-form-item>
                    </nz-row> 
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Área </span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzPlaceHolder="Selecciona el área " formControlName="area"  [(ngModel)]="selectarea">
                                <nz-option *ngFor="let data of areaData" [nzLabel]="data.nombre_area_funcional" [nzValue]="data.id_area_funcional"></nz-option>
                            </nz-select>
                        </nz-form-control>
                        <a class="ant-btn ant-btn-dashed" (click)="verNuevaArea()">Nueva área</a>
                    </nz-form-item>
                    <!-- Nueva área -->
                    <form nz-form nz-row  [formGroup]="validateFormArea"  class="formularioArea" *ngIf="verArea">
                        <nz-row>
                            <nz-form-item class="items-personalize">
                                <nz-form-label [nzRequired]="false" class="lbl_new"><span class="lbl-form_new">Nueva área </span></nz-form-label>
                                <nz-form-control class="control-paralelo">
                                    <input type="text" nz-input formControlName="new" placeholder="Ingrese la información" nzPlacement="rightTop" nz-tooltip>
                                </nz-form-control> 
                            </nz-form-item>
                        </nz-row> 
                        <nz-row>
                            <a class="send_new" (click)="guardarArea()">Guardar área</a>
                        </nz-row>         
                    </form>

                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Sede </span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzPlaceHolder="Selecciona la sede " formControlName="sede"  [(ngModel)]="selectsede">
                                <nz-option *ngFor="let data of sedeData" [nzLabel]="data.nombre" [nzValue]="data.id_departamento"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form">Fecha de caducidad </span></nz-form-label>
                        <nz-date-picker nzDisabledTime formControlName="fecha_caducidad" [(ngModel)]="fechaCaducidad" class="fecha"></nz-date-picker>
                    </nz-form-item>
                    
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Competencias </span></nz-form-label>
                        <nz-form-control>
                            <nz-select [nzMaxTagCount]="5"
                                nzMode="multiple" nzPlaceHolder="Selecciona las competencias " formControlName="competencias"  [(ngModel)]="competencias">
                                <nz-option *ngFor="let data of competenciasData" [nzLabel]="data.nombre_competencia" [nzValue]="data.id_competencia"></nz-option>
                            </nz-select>
                        </nz-form-control>
                        <a class="ant-btn ant-btn-dashed" (click)="verNuevaCompetencia()">Nueva competencia</a>
                    </nz-form-item>

                    <!-- Nueva competencia -->
                    <form nz-form  [formGroup]="validateFormCompetencia"  class="formularioCompetencia" *ngIf="verCompetencia">
                        <nz-row>
                            <nz-form-item class="items-personalize">
                                <nz-form-label [nzRequired]="false" class="lbl_new"><span class="lbl-form_new">Nueva competencia </span></nz-form-label>
                                <nz-form-control class="control-paralelo">
                                    <input type="text" nz-input formControlName="new" placeholder="Ingrese la información" nzPlacement="rightTop" nz-tooltip>
                                </nz-form-control> 
                            </nz-form-item>
                        </nz-row> 
                        <nz-row>
                            <a class="send_new" (click)="guardarCompetencia()">Guardar competencia</a>
                        </nz-row>         
                    </form>

                </div>
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 10, offset:2  }" [nzLg]="{ span: 10, offset:2  }">
                    <form nz-form [formGroup]="validateFormObjetivo">
                        <nz-form-item *ngFor="let control of listOfControl; let i = index" class="items-personalize">
                            <nz-form-label *ngIf="i == 0" [nzFor]="control.controlInstance" class="lbl">
                                <span class="lbl-form color-kaizen">Objetivos del cargo</span>
                            </nz-form-label>
                            <nz-form-control [nzXs]="24" [nzSm]="20" [nzOffset]="i == 0 ? 0 : 0" nzErrorTip="Please input passenger's name or delete this field.">
                            <input class="passenger-input" nz-input placeholder="Ingrese los objetivos"
                                [attr.id]="control.id" [formControlName]="control.controlInstance"/>
                            <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="removeField(control, $event)"></i>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 24, offset: 0 }">
                            <button nz-button nzType="dashed" class="add-button" (click)="addField($event)">
                                <i nz-icon nzType="plus"></i>
                                Agregar objetivo
                            </button>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                    <form nz-form [formGroup]="validateFormRequisito">
                        <nz-form-item *ngFor="let controlrequisito of listOfControlRequisito; let i = index" class="items-personalize">
                            <nz-form-label *ngIf="i == 0" [nzFor]="controlrequisito.controlInstance" class="lbl">
                                <span class="lbl-form color-kaizen">Requisitos para el cargo</span>
                            </nz-form-label>
                            <nz-form-control [nzXs]="24" [nzSm]="20" [nzOffset]="i == 0 ? 0 : 0" nzErrorTip="Please input passenger's name or delete this field.">
                            <input class="passenger-input" nz-input placeholder="Ingrese el requisito"
                                [attr.id]="controlrequisito.id" [formControlName]="controlrequisito.controlInstance"/>
                            <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="removeFieldRequisito(controlrequisito, $event)"></i>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 24, offset: 0 }">
                            <button nz-button nzType="dashed" class="add-button" (click)="addFieldRequisito($event)">
                                <i nz-icon nzType="plus"></i>
                                Agregar requisito
                            </button>
                            </nz-form-control>
                        </nz-form-item>   
                    </form>
                    <!--<form nz-form [formGroup]="validateFormCompetencia">
                        <nz-form-item *ngFor="let controlcompetencia of listOfControlCompetencia; let i = index" class="items-personalize">
                            <nz-form-label *ngIf="i == 0" [nzFor]="controlcompetencia.controlInstance" class="lbl">
                                <span class="lbl-form color-kaizen">Competencias para el cargo</span>
                            </nz-form-label>
                            <nz-form-control [nzXs]="24" [nzSm]="20" [nzOffset]="i == 0 ? 0 : 0" nzErrorTip="Please input passenger's name or delete this field.">
                            <input class="passenger-input" nz-input placeholder="Ingrese la competencia"
                                [attr.id]="controlcompetencia.id" [formControlName]="controlcompetencia.controlInstance"/>
                            <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="removeFieldCompetencia(controlcompetencia, $event)"></i>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 24, offset: 0 }">
                            <button nz-button nzType="dashed" class="add-button" (click)="addFieldCompetencia($event)">
                                <i nz-icon nzType="plus"></i>
                                Agregar competencia
                            </button>
                            </nz-form-control>
                        </nz-form-item>  
                    </form>-->
                </div>
                
            </nz-row> 
            <nz-row>
                <a class="send" (click)="enviarOferta()">Guardar Oferta</a>
            </nz-row>         
        </form>
    </div>
    <div nz-col [nzXs]="{span:24, offset:0}"  [nzMd]="{ span: 22, offset:2 }" [nzLg]="{ span: 22, offset:2 }">
        
    </div>
        
    <!--<nz-col [nzXs]="{span:20, offset:2 }"  [nzMd]="{ span: 10, offset:2 }" [nzLg]="{ span: 8, offset:2 }">
        

        <form nz-form  [formGroup]="validateFormObjetivo"  class="formularioObjetivo">
            <nz-row>
                <nz-form-item class="items-personalize">
                    <nz-form-label [nzRequired]="false" class="lbl_new"><span class="lbl-form_new">Nuevo objetivo </span></nz-form-label>
                    <nz-form-control class="control-paralelo">
                        <input type="text" nz-input formControlName="new" placeholder="Ingrese la información" nzPlacement="rightTop" nz-tooltip>
                    </nz-form-control> 
                </nz-form-item>
            </nz-row> 
            <nz-row>
                <a class="send_new" (click)="guardarObjetivo()">Guardar objetivo</a>
            </nz-row>         
        </form>

        <form nz-form  [formGroup]="validateFormRequisito"  class="formularioRequisito">
            <nz-row>
                <nz-form-item class="items-personalize">
                    <nz-form-label [nzRequired]="false" class="lbl_new"><span class="lbl-form_new">Nuevo requisito </span></nz-form-label>
                    <nz-form-control class="control-paralelo">
                        <input type="text" nz-input formControlName="new" placeholder="Ingrese la información" nzPlacement="rightTop" nz-tooltip>
                    </nz-form-control> 
                </nz-form-item>
            </nz-row> 
            <nz-row>
                <a class="send_new" (click)="guardarRequisito()">Guardar requisito</a>
            </nz-row>         
        </form>

        
    </nz-col>-->
    
</div>