<nz-row class="add-compatibilidad">
    <h2 class="color-kaizen title-contact">Equivalentes</h2>
    <nz-col [nzXs]="{span:20, offset:2 }"  [nzMd]="{ span: 20, offset:2 }" [nzLg]="{ span: 20, offset:2 }">
        <form nz-form [formGroup]="validateForm" class="formularioAccount" (ngSubmit)="submitForm()">
            <nz-row>
                <!-- Nro de Parte -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="nroparte" class="lbl"><span class="lbl-form color-kaizen">Repuesto nro. de Parte</span></nz-form-label>
                        <nz-form-control>
                            <nz-select
                            [(ngModel)]="listOfTagOptionsnroparte"
                            nzMode="tags"
                            formControlName="nroparte"
                            [nzTokenSeparators]="[',']"
                            nzPlaceHolder="Seleccione el nro de parte"
                            >
                            <nz-option *ngFor="let option of listOfOptionnroparte" [nzLabel]="option.numero_parte" [nzValue]="option.id_repuesto"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!-- Marca -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11, offset:1 }" [nzLg]="{ span: 11, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="marca" class="lbl"><span class="lbl-form color-kaizen">Marca Vehiculo</span></nz-form-label>
                        <nz-form-control>
                            <nz-select
                            [(ngModel)]="listOfTagOptionsmarca"
                            nzMode="tags"
                            formControlName="marca"
                            [nzTokenSeparators]="[',']"
                            nzPlaceHolder="Seleccione la marca"
                            >
                            <nz-option  *ngFor="let data of listOfOptionmarca" [nzLabel]="data.nombre_marca" [nzValue]="data.id_marca_vehiculo"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!-- equivalente -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="equivalente" class="lbl"><span class="lbl-form color-kaizen">Equivalente:</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="equivalente" placeholder="Ej: 2021" nzTitle="Por Favor ingrese el equivalente" [(ngModel)]="input_equivalente" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('equivalente').dirty && validateForm.get('equivalente').errors">Por Favor ingrese el equivalente!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!--Boton Enviar-->
                <div nz-col nzSpan="24">                        
                    <div nz-col [nzXs]="23" [nzSm]="{span:12, offset:6}" style="text-align:center;">
                        <a class="btn-send back-kaizen" id="enviar" (click)="submitForm()">Guardar Equivalente</a>
                    </div>
                </div>
            </nz-row>
        </form>
    </nz-col>
</nz-row>