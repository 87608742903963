<nz-row class="clientes">
    <nz-row>
        <div class="btn-download">
            <a style="cursor: pointer" (click)="exportexcel()">
                <i nz-icon nzType="download" nzTheme="outline"></i> Descargar Reporte
            </a>
            <h2 class="report-title">Pago de Servicios/Repuestos por Mostrador </h2>
        </div>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="4" nzOffset="6">
          <a class="control-filter" (click)="verFiltroFecha()">
              Filtrar por Fecha
          </a>
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
          <div [nzDropdownMenu]="menu"
          nz-dropdown
          #dropdown="nzDropdown"
          [class.ant-table-filter-open]="dropdown.nzVisible"
          nzTrigger="click"
          nzPlacement="bottomRight"
          [nzClickHide]="false"
          nzTableFilter class="control-filter">
              Buscar por transacción
          </div>
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
          <a class="control-filter">
            Buscar por Cliente
          </a>
      </nz-col>
    </nz-row>
    <!--Espacios de Filtros -->
    <nz-row class="block-filter" *ngIf="filtroFecha">
      <h4 class="filter-title">Filtrar por Fecha</h4>
      <nz-col nzSpan="6" nzOffset="4">
        <span class="filter-lbl">Fecha Inicial :</span>
        <nz-date-picker name="fechaInicial" nzDisabledTime [(ngModel)]="fechaInicial" (ngModelChange)="setFechaInicial()"></nz-date-picker>
      </nz-col>
      <nz-col nzSpan="6" nzOffset="1">
        <span class="filter-lbl">Fecha Final :</span>
        <nz-date-picker name="fechaFinal" nzDisabledTime [nzAllowClear]="false"	 [(ngModel)]="fechaFinal" (ngModelChange)="setFechaFinal()"></nz-date-picker>
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
        <a class="btn-filter">Filtrar</a>
      </nz-col>
    </nz-row>

    <!-- FILTRAR POR CI -->

    <nz-row class="block-filter" *ngIf="filtroCI">
      <h4 class="filter-title">Filtrar por Nro. orden</h4>
      <nz-col nzSpan="6" nzOffset="6">
        <span class="filter-lbl">Nro. Orden :</span>
        <input nz-input placeholder="0000" [(ngModel)]="valueCI" />
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
        <a class="btn-filter">Filtrar</a>
      </nz-col>
    </nz-row>
    
    <nz-row style="overflow-x:auto;">
      <nz-table #nestedTable [nzData]="mostrarTransacciones" [nzPageSize]="10" nzTableLayout="fixed" id="excel-table" class="tabla-listado-servicios">
        <thead>
            <tr>
                <th nzShowExpand></th>
                <th nzShowSort [(nzSort)]="sortValue" (nzSortChange)="search(searchName)">Nro. Orden Servicio</th>
                <th>Nro. Transaccion</th>
                <th>Fecha Transaccion</th>
                <th>Cliente</th>
                <th>Descripcion</th>
                <th>Fecha del Servicio</th>
                <th>Total Cobrar Bs</th>
                <th nzShowFilter [nzFilters]="listOfFilterEstado" (nzFilterChange)="filterAddressChange($event)">Estado Transacción</th>
                <!--<th>Editar Estado Transacción</th>-->
            </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-data let-index="index" [ngForOf]="nestedTable.data" >
            <tr>
              <td nzShowExpand [(nzExpand)]="data.expand" (click)="obtenerProductos(index)"></td>
              <td>{{ data.nro_orden }}</td>
              <td>{{ data.nro_transaccion_metodo_pago }}</td>
              <td>{{ data.created_at }}</td>
              <td>{{ data.nombre_cliente}}</td>
              <td>{{ data.descripcion }}</td>
              <td>{{ data.fecha }}</td>
              <td>{{ data.monto_total }}</td>
              <td>
                <nz-badge *ngIf="data.estado === 3" [nzStatus]="'warning'" [nzText]="'Cancelada'"></nz-badge>
                  <nz-badge *ngIf="data.estado === 2" [nzStatus]="'warning'" [nzText]="'Pendiente'"></nz-badge>
                  <nz-badge *ngIf="data.estado === 1" [nzStatus]="'processing'" [nzText]="'Pagado'"></nz-badge>
              </td>            
            </tr>
            <tr [nzExpand]="data.expand">
              <td></td>
              <td colspan="7">
                  <img [src]="data['pago_servicio'].imagen_qr" class="img-qr"/>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="search-box">
          <input type="text" nz-input placeholder="Buscar por Transacción" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="searchTransaccion()" class="search-button">
            Buscar
          </button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </nz-dropdown-menu>
    </nz-row>
</nz-row>