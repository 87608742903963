<nz-row class="clientes">
    <div class="btn-download">
        <h2 class="report-title"> Registro de clientes </h2>
    </div>
    <nz-row *ngIf="!verContent">
        
        <div class="client-search">
            <div class="client-search-input">
                <label class="lbl">Ingresa el CI del cliente</label>
                <div class="example-input">
                    <input nz-input placeholder="CI" [(ngModel)]="ciCliente" nzSize="large" />
                </div>
            </div>
            
            <a class="client-search-btn" (click)="buscar()">Buscar cliente</a>
        </div>
    </nz-row>
    <div nz-col nzSpan="10" *ngIf="verContent">
        <nz-row>
            <div class="client-pdv">
                <h2 class="client-section">Cliente</h2>
            </div>
            <div class="client-info">
                <label class="lbl">CI :</label>
                <div class="client-input">
                    <input nz-input  [(ngModel)]="ciCliente" placeholder="CI" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Código :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="codigo" placeholder="Código" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Nombre completo :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="nombre_completo" placeholder="Nombre completo" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Razón Social :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="razon_social" placeholder="Razón social" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl"> NIT :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="nit" placeholder="NIT" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Teléfono :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="telefono" placeholder="Ej: 79856844" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Dirección :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="direccion" placeholder="Ej: Ev. Virjen de cotoca entre 3er anillo..." nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Correo electrónico :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="correo" placeholder="Ej: mail@mail.com" nzSize="large" />
                </div>
            </div>
            <div class="client-pdv">
                <h2 class="client-section">PDV</h2>
            </div>
            <div class="client-info">
                <label class="lbl">Tipo de PDV :</label>
                <div class="client-select">
                    <nz-select [(ngModel)]="tipoPDV" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option nzValue="Subdistribuidor" nzLabel="Subdistribuidor"></nz-option>
                        <nz-option nzValue="2" nzLabel="Tipo 2"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Nombre del encargado :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="nombre_encargado" placeholder="Ej: Pedro Perez" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Teléfono del encargado :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="telefono_encargado" placeholder="Ej: 79856844" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Especialidad del PDV :</label>
                <div class="client-select">
                    <nz-select [(ngModel)]="especialidadPDV" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option nzValue="Venta de repuestos" nzLabel="Venta de repuestos"></nz-option>
                        <nz-option nzValue="2" nzLabel="Tipo 2"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Compras frecuentes :</label>
                <div class="client-select">
                    <nz-select [(ngModel)]="comprasFrecuentesPDV" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option nzValue="Antiguo" nzLabel="Antiguo"></nz-option>
                        <nz-option nzValue="2" nzLabel="Tipo 2"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Promedio de compra :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="promedio_compra" placeholder="Ej: 1015,63" nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Compromiso de compra ($us.) :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="compromiso_compra" placeholder="Ej: 2000" nzSize="large" />
                </div>
            </div>
    
        </nz-row>
    </div>
    <div nz-col nzSpan="10" nzOffset="2" *ngIf="verContent">
        <nz-row>
            <div class="client-pdv">
                <h2 class="client-section">PDV</h2>
            </div>
            <div>
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"  style="height: 300px;" class="item-form item-rd">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                </agm-map>
            </div>
            <div class="client-info">
                <label class="lbl">Dirección :</label>
                <div class="client-input">
                    <input nz-input [(ngModel)]="pdv_direccion" placeholder="Ej: Ev. Virjen de cotoca entre 3er anillo..." nzSize="large" />
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Georeferencia :</label>
                <div>
                    Latitud : <strong>{{latitude}}</strong>
                </div>
                <div>
                    Longitud : <strong>{{longitude}}</strong>
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Imagen interior:</label>
                <div class="clearfix">
                    <input type="file"  [(ngModel)]="interior" id="upload-cv" accept=".jpg" (change)="subirFoto($event)" style="display: none;" />
                        <a class="add-btn" (click)="clickSubirFoto()" *ngIf="!cargoHV">
                            Cargar Foto
                        </a>

                        <span class="txt-pdf" *ngIf="!cargoHV">"Imagen como formato sugerido"</span>
                        <span class="txt-pdf" *ngIf="cargoHV">"Foto cargada"</span>
                        <div id="padre-canvas-cv">    
                        </div>    
                </div>
            </div>
            <div class="client-info">
                <label class="lbl">Imagen Exterior:</label>
                <div class="clearfix">
                    <input type="file"  [(ngModel)]="exterior" id="upload-foto" accept=".jpg" (change)="subirFotoExterior($event)" style="display: none;" />
                        <a class="add-btn" (click)="clickSubirFotoExterior()" *ngIf="!exteriorHV">
                            Cargar Foto
                        </a>

                        <span class="txt-pdf" *ngIf="!exteriorHV">"Imagen como formato sugerido"</span>
                        <span class="txt-pdf" *ngIf="exteriorHV">"Foto cargada"</span>
                        <div id="padre-canvas-exterior">    
                        </div>    
                </div>
            </div>

            
        </nz-row>
    </div>
    
</nz-row>

<a (click)="enviarCita()" class="btn-send"  *ngIf="verContent">Guardar cliente</a>