<nz-row class="clientes">
    <nz-row>
        <div class="btn-download">
            <a style="cursor: pointer" (click)="exportexcel()">
                <i nz-icon nzType="download" nzTheme="outline"></i> Descargar Reporte
            </a>
            <h2 class="report-title"> Repuestos en Tienda </h2>
        </div>
    </nz-row>
    
    <nz-row class="controles-repuestos">
        <nz-col [nzXs]="{span:22, offset:1}" [nzMd]="{ span: 10, offset: 2 }" [nzLg]="{ span: 10, offset: 2 }">
           <!-- <nz-select nzShowSearch nzPlaceHolder="Seleccione una marca" [(ngModel)]="selectedValue" (ngModelChange)="marca($event,selectedValue)">
                <nz-option nzLabel="Todas" nzValue="Todas"></nz-option>
                <nz-option *ngFor="let marcasFiltros of marcas; let i = index" nzLabel="{{marcasFiltros.nombre_marca_repuesto}}" nzValue="{{marcasFiltros.nombre_marca_repuesto}}"></nz-option>
            </nz-select>-->
        </nz-col>

        <nz-col [nzXs]="{span:22, offset:1}" [nzMd]="{ span: 10, offset: 2 }" [nzLg]="{ span: 10, offset: 2 }">
            <!--<div class="sec-organize">Cantidad de productos por página :
                <nz-select style="width: 100px;" nzShowSearch [(ngModel)]="cantidadItems" (ngModelChange)="cantidadItemsMetod($event,cantidadItems)">
                  <nz-option nzValue="12" nzLabel="12"></nz-option>
                  <nz-option nzValue="30" nzLabel="30"></nz-option>
                  <nz-option nzValue="60" nzLabel="60"></nz-option>
                  <nz-option nzValue="90" nzLabel="90"></nz-option>
                  <nz-option nzValue="120" nzLabel="120"></nz-option>
                </nz-select> 
            </div>-->
        </nz-col>
    </nz-row>


    <nz-row style="overflow-x:auto;">
        <nz-table #basicTable [nzData]="repuestosDatos" id="excel-table" class="table-repuestos">
            <thead>
              <tr>
               
                <th>Imagen</th>
                <th>Nro. Parte</th>
                <th>Nombre Repuesto</th>
                <th>Precio</th>
                <th>Stock</th>
                <th>Nro. parte alternativo</th>
                <th>Marca</th>
                <th>Grupo</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of repuestosDatos">
                    
                    <td>
                      <img [src]="data.imagen_repuesto" class="img-repuesto"/>
                    </td>
                    <td>{{ data.numero_parte }}</td>
                    <td>{{ data.nombre_repuesto }}</td>
                    <td>{{ data.precio }}</td>
                    <td>{{ data.stock }}</td>
                    <td>{{ data.numero_parte_alternativo }}</td>  
                    <td>{{ data.nombre_marca_repuesto }}</td>
                    <td>{{ data.nombre_tipo_repuesto }}</td>
                </tr>
            </tbody>
        </nz-table>

    </nz-row> 
    <!--<nz-row>
        <nz-pagination [nzPageIndex]="configPage.currentPage" [nzTotal]="totalRepuestos" [nzPageSize]="configPage.itemsPerPage" (nzPageIndexChange)="pageChanged($event)"></nz-pagination>
    </nz-row> -->
</nz-row>