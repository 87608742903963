import { Component, OnInit } from '@angular/core';
import { ClientesService } from '../services/clientes.service';
import * as XLSX from 'xlsx'; 
import differenceInCalendarDays from 'date-fns/differenceInBusinessDays';
import * as moment from 'moment';
import { stringify } from 'querystring';


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

    clientesDatos ;

    /*name of the excel-file which will be downloaded. */ 
    reporteEncuestas= 'Clientes-Kaizen.xlsx'; 
    today = new Date();   
    fechaInicial;
    fechaFinal;
    horas = [];
    horaSelected;
    filtroFecha;
    filtroCI;
    valueCI: string;

    //Filtros
    listOfFilterGenero = [{ text: 'Femenino', value: 'F' }, { text: 'Marculino', value: 'M' }];
    sortClientID: string | null = null;
    sortValue: string | null = null;
    searchValue = '';
    listOfSearchApellidos: string[] = [];
    listOfDisplayData;

    constructor(private clientes: ClientesService) {
        this.filtroFecha = false;
        this.filtroCI = false;
    }

    verFiltroFecha() {
      this.filtroFecha = true;
      this.filtroCI = false;
    }

    verFiltroCI() {
      this.filtroFecha = false;
      this.filtroCI = true;
    }

    ngOnInit(): void {
        this.mostrarRepuestos();
    }

    mostrarRepuestos() {
      this.clientes.obtenerclientes().subscribe( data => {
        this.clientesDatos = data;
        this.listOfDisplayData = this.clientesDatos;
        /*console.log(this.clientesDatos);*/
      }, error => {
          console.log(error); // Imprimimos en consola el error
      });
    }

    exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.reporteEncuestas);
			
    }
  
    setFechaInicial() {
      const fecha = moment(this.fechaInicial).format('YYYY-MM-DD');    
      const hora = moment(this.horaSelected, "HH:mm").format('HH:mm');    
      this.fechaInicial = moment(fecha).format('YYYY-MM-DD');
      console.log("FI:  " + this.fechaInicial);    
    }

    setFechaFinal() {
      const fecha = moment(this.fechaFinal).format('YYYY-MM-DD');    
      const hora = moment(this.horaSelected, "HH:mm").format('HH:mm');    
      this.fechaFinal = moment(fecha).format('YYYY-MM-DD');    
      console.log("FF:  " + this.fechaFinal);    
    }
  
    mostrarFechaHora(params) {
      if (params) {
        return moment(this.fechaInicial).format('DD-MM-YYYY');
      } else {
        return moment(this.fechaInicial).format('HH:mm');
      }
    }

    reset(): void {
      this.searchValue = '';
      this.search();
    }
  
    sort(sortName: string, value: string): void {
      this.sortClientID = sortName;
      this.sortValue = value;
      this.search();
    }
  
    filterAddressChange(value: string[]): void {
      this.listOfSearchApellidos = value;
      this.search();
    }

    search(): void {
        
      const filterFunc = (item: { apellidos: string; ciudad: string; direccion: string; fecha_nacimiento: string; genero: string; id_persona: Number; latitud: string; longitud: string; nom_tit: string; nombre: string; nro_documento: string }) => {
        return (
          (this.listOfSearchApellidos.length
            ? this.listOfSearchApellidos.some(address => item.genero.indexOf(address) !== -1)
            : true) && item.nro_documento.indexOf(this.searchValue) !== -1
        );
      };
      const data = this.clientesDatos.filter((item: { apellidos: string; ciudad: string; direccion: string; fecha_nacimiento: string; genero: string; id_persona: Number; latitud: string; longitud: string; nom_tit: string; nombre: string; nro_documento: string }) => filterFunc(item));
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortClientID!] > b[this.sortClientID!]
            ? 1
            : -1
          : b[this.sortClientID!] > a[this.sortClientID!]
          ? 1
          : -1
      );
    }
}
