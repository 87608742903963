import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

    token: string;
    constructor(private http: HttpClient) {
      if (localStorage.getItem('userKaizenDashboard')) {
        this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
      }
    }

    // Servicio que obtiene las marcas de los repuestos
    obtenerclientes() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cliente`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las marcas de los repuestos
    obtenerclientesTeros() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cliente/teros`;
      return this.http.get<any>(url, httpOptions);
    }

    // Edicion del estado de deliverys
    actualizarClientesTeros(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cliente/teros`;
      return this.http.post<any>(url,data, httpOptions);
    }

    // Edicion del estado de deliverys
    guardarClienteB2B(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/usuario/b2b`;
      return this.http.post<any>(url,data, httpOptions);
    }

    // Servicio que obtiene las marcas de los repuestos
    buscarClienteB2B(ci:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cliente/teros/documento/${ci}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las marcas de los repuestos
    listarClienteB2B() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/cliente/b2b`;
      return this.http.get<any>(url, httpOptions);
    }
}
