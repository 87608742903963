import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/acceso/login.service';
import { first, map } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  loading: boolean = false;
  mail;
  pass;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    //this.router.navigate(['/home']);
  }

  constructor(public loginServ: LoginService, private fb: FormBuilder, private router: Router, private message: NzNotificationService) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }

  sendLogin() {
    const data = {
      email: this.mail,
      password: this.pass
    };

    this.loginServ.doLogin(data).pipe(first()).subscribe(data => {
      localStorage.setItem('userKaizenDashboard', JSON.stringify(data));
      this.loading = false;
      this.router.navigate(['/home']);
    }, error => {
        this.message.error("Dashboard eCommerce", error);
        console.log(error);
        if(error.status === 400) {
          this.loading = false;
          this.message.error("Dashboard eCommerce", "Usuario y contraseña incorrectos");
        }
        if(error.status === 422) {
          this.mail = "";
          this.pass = "";
          this.message.error("Dashboard eCommerce", "Usuario y contraseña incorrectos");
        }
    })
  }
}
