import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UploadFile } from 'ng-zorro-antd';
import { first } from 'rxjs/operators';
import { ClientesService } from 'src/app/services/clientes.service';

@Component({
  selector: 'app-b2b-registro-clientes',
  templateUrl: './b2b-registro-clientes.component.html',
  styleUrls: ['./b2b-registro-clientes.component.css']
})
export class B2bRegistroClientesComponent implements OnInit {
  ciCliente:any;
  codigo:any;
  nombre_completo:any;
  razon_social:any;
  nit:any;
  telefono:any;
  direccion:any;
  correo:any;
  nombre_encargado:any;
  telefono_encargado: any;
  tipoPDV = "Subdistribuidor";
  especialidadPDV = "Venta de repuestos";
  comprasFrecuentesPDV = "Antiguo";
  promedio_compra:any;
  compromiso_compra:any;
  pdv_direccion:any;

  /* Info to Map */
  latitude: number=0;
  longitude: number=0;
  zoom: number=0;
  address: string="";
  private geoCoder:any;
  @ViewChild('search') public searchElementRef!: ElementRef;

  /* Imágenes */
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };
  fileList = [
    {
      uid: -1,
      name: 'xxx.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    }
  ];
  previewImage: string | undefined = '';
  previewVisible = false;

  verContent=false;
  cargoHV:any;
  documentoCV:any;

  exteriorHV:any;
  exteriorCV:any;

  interior:any;
  exterior:any;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private clientesService: ClientesService) {
      this.cargoHV = false;
      this.exteriorHV = false;
  }

  ngOnInit(): void {
  }
  buscar(){
    this.clientesService.buscarClienteB2B(this.ciCliente).pipe(first()).subscribe( data => {
      console.log(data)
      setTimeout(() => {
          alert("Cliente encontrado");
          this.verContent=true;
          this.nombre_completo = data.nom_tit;
          this.codigo = data.cod_tit;
          this.telefono = data.tel_celular;
          this.correo = data.email;
          this.direccion = data.direccion;
      },100)
  }, error => {
    alert("Cliente no encontrado");
    this.verContent=false;
  });
    
  }
  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };

  ngAfterViewInit() {
      //Datos del mapa
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
  
        /*let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["address"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: any = autocomplete.getPlace();
  
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;
          });
        });*/
      });
  
    }

     // Get Current Location Coordinates
    setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 15;
          this.getAddress(this.latitude, this.longitude);
        });
      }
    }

    markerDragEnd($event: any) {
        //console.log($event);
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
    }

    getAddress(latitude:any, longitude:any) {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: string | any[], status: string) => {      
        if (status === 'OK') {
          if (results) {
            //console.log(results);
            let indice = results.length > 9 ? 3 : 2;
            this.zoom = 12;
            this.address = results[indice].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
  
      });
    }

    subirFoto(e:any) {
      const obj = e.target.files
      const that = this;
      let cargar = (file: any) => {
          let padre : any | null = document.getElementById('padre-canvas-cv');
          
          const reader = new FileReader();
      
          reader.onload = function(){
              
              const image = new Image();
              image.height = 100;
              image.title = file.name;
              image.src = "../../../assets/fonts/icons/jpg.png";
              that.cargoHV = true;
              //console.log(this.result);
                //that.documentoCV.push(file);
              
                that.documentoCV = file;
                
              console.log(that.documentoCV);

              padre.appendChild( image );
            };
            reader.readAsDataURL(file);
      }

      if (obj) {
        [].forEach.call(obj, cargar);
    }
    }

    clickSubirFoto() { 
      let itemDOM: any | null = document.getElementById('upload-cv');
        itemDOM.click();
    }

    subirFotoExterior(e:any) {
      const obj = e.target.files
      const that = this;
      let cargar = (file: any) => {
          let padre : any | null = document.getElementById('padre-canvas-exterior');
          
          const reader = new FileReader();
      
          reader.onload = function(){
              
              const image = new Image();
              image.height = 100;
              image.title = file.name;
              image.src = "../../../assets/fonts/icons/jpg.png";
              that.exteriorHV = true;
              //console.log(this.result);
                //that.documentoCV.push(file);
              
                that.exteriorCV = file;
                
              console.log(that.exteriorCV);

              padre.appendChild( image );
            };
            reader.readAsDataURL(file);
      }

      if (obj) {
        [].forEach.call(obj, cargar);
    }
    }
    clickSubirFotoExterior() { 
      let itemDOM: any | null = document.getElementById('upload-foto');
        itemDOM.click();
    }

  

    enviarCita() {
      
      let datos = {};
      
      datos = {
        cod_tit:this.ciCliente,
        nombre_completo:this.nombre_completo,
        razon_social:this.razon_social,
        telefono:this.telefono,
        nit:this.nit,
        direccion:this.direccion,
        email:this.correo,
        tipo_pdv:this.tipoPDV,
        nombre_encargado:this.nombre_encargado,
        telefono_encargado:this.telefono_encargado,
        especialidad_pdv:this.especialidadPDV,
        compra_frecuente:this.comprasFrecuentesPDV,
        promedio_compra:this.promedio_compra,
        compromiso_compra:this.compromiso_compra,
        latitud:this.latitude,
        longitud:this.longitude,
        descuento:10,
        imagen_interior:this.documentoCV,
        imagen_exterior:this.exteriorCV
      }
          
        console.log(datos);
  
        this.clientesService.guardarClienteB2B(datos).subscribe( result => {
            if (result!=null) {
              console.log(result);
              if(result.message) {
                alert("No se pudo agendar "+ result.message);
                switch (result.message) {
                  case "Cita ya agendada":
                    alert("Esta placa ya tiene una cita agendada");
                    break;
                  case "Cantidad máxima de citas por horario alcanzado":
                    alert("Cantidad máxima de citas por horario alcanzado");
                    break;
                
                  case "The given data was invalid.":
                    alert("Presione el botón buscar en la placa");
                    break;
  
                  default:
                    break;
                }
              } else {
                alert("Cita agendada!!!");
                localStorage.setItem('KaizenDashboard', 'mostrarCitas');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
              
            } else {
              alert("No se pudo agendar");
            }
        });
    }
  
}
