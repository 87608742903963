import { Component, OnInit } from '@angular/core';
import { BitacoraService } from '../services/bitacora.service';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit {

  bitacoradata;

  constructor(private bitacora: BitacoraService) {
    this.mostrarBitacora();
   }

  ngOnInit(): void {
  }

  mostrarBitacora() {
    this.bitacora.obtenerBitacora().subscribe( data => {
      this.bitacoradata = data;
      console.log(this.bitacoradata);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
}
