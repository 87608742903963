import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddrepuestosService {
  token: string;
  constructor(private http: HttpClient) { 
    if (localStorage.getItem('userKaizenDashboard')) {
      this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
    }
  }

  // Servicio que obtiene las marcas de los repuestos
  getMarcasRepuesto() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto/marca`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtiene las marcas de los repuestos
  getMarcasVehiculos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/vehiculo/marca`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtiene los tipos de repuestos
  getTipoRepuesto() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto/categoria`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtiene los tipos de repuestos
  getModelosRepuesto(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/vehiculo/marca/${id}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Edicion del estado de transacciones
  addRepuesto(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto`;
    return this.http.post<any>(url,data, httpOptions);
  }

  // Edicion del estado de transacciones
  addMarcaRepuesto(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto/marca`;
    return this.http.post<any>(url,data, httpOptions);
  }

  

  // Edicion del estado de transacciones
  addModelo(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/vehiculo/modelo`;
    return this.http.post<any>(url,data, httpOptions);
  }

  
}