<h2 class="color-kaizen title-bolsa">Ofertas laborales</h2>
<nz-row style="overflow-x:auto;">
    <nz-table #basicTable [nzData]="listOfDisplayData" nzTableLayout="fixed" id="excel-table" class="table-clientes">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha creación</th>
            <th>Fecha caducidad</th>
            <th>Área</th>
            <th>Cargo </th>
            <th>Departamento</th>
            <th>Estado</th>
            <th>Compartir</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of listOfDisplayData; let i=index">
                <td>{{ data.id_empleo }}</td>
                <td>{{ data.fecha_creacion }}</td>
                <td>{{ data.fecha_caducidad }}</td>
                <td>{{ data.nombre_area_funcional }}</td>
                <td>{{ data.cargo }}</td>
                <td>{{ data.nombre_departamento }}</td>
                <td>{{ data.nombre_estado }}</td>
                <td class="share-rrss">
                  <div class="fb-share-button share-fb" data-href="https://www.kaizenmotors.com" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.kaizenmotors.com%2Fbolsa-trabajo%2F{{data.id_empleo}}" class="fb-xfbml-parse-ignore"><span class="icon-facebook"></span> Facebook</a></div>
                  <a data-action="share/whatsapp/share" href="https://api.whatsapp.com/send?text=https%3A%2F%2Fwww.kaizenmotors.com%2Fbolsa-trabajo%2F{{data.id_empleo}}" target="_blank" class="share-wp"><span class="icon-whatsapp"></span> WhatsApp</a>
                  <a class="share-linkedin" href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.kaizenmotors.com%2Fbolsa-trabajo%2F{{data.id_empleo}}&title={{ data.cargo }}&summary={{ data.cargo }}&source=Kaizen%2FMotors&target=_blank&rel=noopener" target="_blank">
                  LinkedIn
                  </a>
                  
                  <button (click)="copyText(data.id_empleo)" value="bco-bnb" class="copy">Copiar link</button>
                </td>
            </tr>
        </tbody>
    </nz-table>

</nz-row>  