<nz-row class="add-compatibilidad">
    <h2 class="color-kaizen title-contact">Agregar Compatibilidad</h2>
    <nz-col [nzXs]="{span:20, offset:2 }"  [nzMd]="{ span: 20, offset:2 }" [nzLg]="{ span: 20, offset:2 }">
        <form nz-form [formGroup]="validateForm" class="formularioAccount" (ngSubmit)="submitForm()">
            <nz-row>
                <!-- Nro de Parte -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="nroparte" class="lbl"><span class="lbl-form color-kaizen">Nro. de Parte</span></nz-form-label>
                        <nz-form-control>
                            <nz-select
                            [(ngModel)]="listOfTagOptions"
                            nzMode="tags"
                            formControlName="nroparte"
                            [nzTokenSeparators]="[',']"
                            nzPlaceHolder="Seleccione el nro de parte"
                            >
                            <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.numero_parte" [nzValue]="option.id_repuesto"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-- Marca del vehiculo -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 7, offset:1 }" [nzLg]="{ span: 7, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="marca" class="lbl"><span class="lbl-form color-kaizen">Marca Vehiculo</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="marca" nzPlaceHolder="Selecciona la marca del repuesto" [(ngModel)]="selectmarca"  (ngModelChange)="obtenerDataModelo(selectmarca)">
                                <nz-option *ngFor="let data of marcaDatos" [nzLabel]="data.nombre_marca" [nzValue]="data.id_marca_vehiculo"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 3, offset:1 }" [nzLg]="{ span: 3, offset:0 }">
                    <a class="add-btn" (click)="showModal('marca')"> <i nz-icon nzType="plus" nzTheme="outline"></i> Nueva Marca</a>
                </div>
                <!-- AGREGAR MARCA -->
                <nz-modal
                [(nzVisible)]="isVisibleMarca"
                [nzTitle]="modalTitleMarca"
                [nzContent]="modalContentMarca"
                [nzFooter]="modalFooterMarca"
                (nzOnCancel)="handleCancel('marca')"
                >
                    <ng-template #modalTitleMarca>
                        <div class="modal-add_title">Agregar Marca</div>
                    </ng-template>

                    <ng-template #modalContentMarca>
                        <form nz-form [formGroup]="validateFormMarca" class="marca-form" (ngSubmit)="submitFormMarca()">
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Por favor ingresa la nueva marca!">
                                <nz-input-group >
                                  <input type="text" nz-input formControlName="nuevamarca" placeholder="Ingresa la marca" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                          </form>
                    </ng-template>

                    <ng-template #modalFooterMarca>
                        <button nz-button nzType="default" class="btn-add" (click)="handleOk('marca')">Agregar Marca</button>
                    </ng-template>
                </nz-modal>

                <!-- Motor -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 8 }" [nzLg]="{ span: 8 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="motor" class="lbl"><span class="lbl-form color-kaizen">Motor</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="motor" nzPlaceHolder="Selecciona un motor" [(ngModel)]="selectmotor">
                                <nz-option *ngFor="let data of motorDatos" [nzLabel]="data.nro_motor" [nzValue]="data.id_motor"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 3, offset:0 }" [nzLg]="{ span: 3, offset:0 }">
                    <a class="add-btn" (click)="showModal('motor')"> <i nz-icon nzType="plus" nzTheme="outline"></i> Nuevo Motor</a>
                </div>
                <!-- AGREGAR MOTOR -->
                <nz-modal
                [(nzVisible)]="isVisibleMotor"
                [nzTitle]="modalTitleMotor"
                [nzContent]="modalContentMotor"
                [nzFooter]="modalFooterMotor"
                (nzOnCancel)="handleCancel('motor')"
                >
                    <ng-template #modalTitleMotor>
                        <div class="modal-add_title">Agregar Motor</div>
                    </ng-template>

                    <ng-template #modalContentMotor>
                        <form nz-form [formGroup]="validateFormMotor" class="marca-form" (ngSubmit)="submitFormMotor()">
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Por favor ingresa el motor!">
                                <nz-input-group >
                                  <input type="text" nz-input formControlName="nromotor" placeholder="Ingresa el nro de  motor" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Por favor ingresa la cilindrada!">
                                  <nz-input-group >
                                    <input type="text" nz-input formControlName="cilindrada" placeholder="Ingresa la cilindrada" />
                                  </nz-input-group>
                                </nz-form-control>
                              </nz-form-item>
                          </form>
                    </ng-template>

                    <ng-template #modalFooterMotor>
                        <button nz-button nzType="default" class="btn-add" (click)="handleOk('motor')">Agregar Motor</button>
                    </ng-template>
                </nz-modal>
                
                
                <!-- Modelo de vehículo -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 7, offset:1 }" [nzLg]="{ span: 7, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="modelo_vehiculo" class="lbl"><span class="lbl-form color-kaizen">Modelo</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="modelo_vehiculo" nzPlaceHolder="Selecciona un modelo de vehiculo" [(ngModel)]="selectmodelovehiculo">
                                <nz-option *ngFor="let data of modelosDatos" [nzLabel]="data.nombre_modelo" [nzValue]="data.id_modelo"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 3, offset:0 }" [nzLg]="{ span: 3, offset:0 }">
                    <a class="add-btn" (click)="showModal('modelo')"> <i nz-icon nzType="plus" nzTheme="outline"></i> Nuevo Modelo</a>
                </div> 
                <!-- AGREGAR MODELO -->
                <nz-modal
                [(nzVisible)]="isVisibleModelo"
                [nzTitle]="modalTitleModelo"
                [nzContent]="modalContentModelo"
                [nzFooter]="modalFooterModelo"
                (nzOnCancel)="handleCancel('modelo')"
                >
                    <ng-template #modalTitleModelo>
                        <div class="modal-add_title">Agregar Modelo</div>
                    </ng-template>

                    <ng-template #modalContentModelo>
                        <form nz-form [formGroup]="validateFormModelo" class="marca-form" (ngSubmit)="submitFormModelo()">
                            <nz-form-item class="items-personalize">
                                <nz-form-label [nzRequired]="false" nzFor="add_marca_modelo" class="lbl"><span class="lbl-form color-kaizen">Marca</span></nz-form-label>
                                <nz-form-control>
                                    <nz-select nzShowSearch formControlName="add_marca_modelo" nzPlaceHolder="Selecciona una marca" [(ngModel)]="selectaddmarca">
                                        <nz-option [nzLabel]="1" [nzValue]="1"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Por favor ingresa el nuevo modelo!">
                                <nz-input-group >
                                  <input type="text" nz-input formControlName="nuevomodelo" placeholder="Ingresa el modelo" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                          </form>
                    </ng-template>

                    <ng-template #modalFooterModelo>
                        <button nz-button nzType="default" class="btn-add" (click)="handleOk('modelo')">Agregar Modelo</button>
                    </ng-template>
                </nz-modal>


                <!-- año -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="anio" class="lbl"><span class="lbl-form color-kaizen">Año:</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="anio" placeholder="Ej: 2021" nzTitle="Por Favor ingrese el número de parte" [(ngModel)]="input_anio" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('nroparte').dirty && validateForm.get('nroparte').errors">Por Favor ingrese el año!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!--Boton Enviar-->
                <div nz-col nzSpan="24">                        
                    <div nz-col [nzXs]="23" [nzSm]="{span:12, offset:6}" style="text-align:center;">
                        <a class="btn-send back-kaizen" id="enviar" (click)="submitForm()">Guardar Compatibilidad</a>
                    </div>
                </div>
            </nz-row>          
        </form>
    </nz-col>
</nz-row>