import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BitacoraService {

    token: string;
    constructor(private http: HttpClient) {
      if (localStorage.getItem('userKaizenDashboard')) {
        this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
      }
    }

    // Servicio que obtiene las marcas de los repuestos
    obtenerBitacora() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bitacora`;
      return this.http.get<any>(url, httpOptions);
    }
}
