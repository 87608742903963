<nz-row class="contacto">
    <h2 class="color-kaizen title-contact">Seguimiento de Contactos</h2>
    <nz-row style="overflow-x:auto;">
        <nz-table #basicTable [nzData]="listOfDisplayData" id="excel-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombres </th>
                <th>Apellidos</th>
                <th>Fecha</th>
                <th>Ciudad</th>
                <th>Correo</th>
                <th>Telefono</th>
                <th>Mensaje</th>
                <th>Estado</th>
                <th>Cambiar Estado</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of listOfDisplayData">
                    <td>{{ data.id_contacto }}</td>
                    <td>{{ data.nombre }}</td>
                    <td>{{ data.apellidos }}</td>
                    <td>{{ data.fecha }}</td>
                    <td>{{ data.ciudad }}</td>
                    <td>{{ data.correo }}</td>
                    <td>{{ data.telefono }}</td>
                    <td>{{ data.mensaje }}</td>
                    <td *ngIf="(data.estado ==='En espera')">
                        <nz-badge nzStatus="warning" nzText="Pendiente"></nz-badge>
                    </td>
                    <td *ngIf="(data.estado ==='Atendido')">
                        <nz-badge nzStatus="processing" nzText="Atendido"></nz-badge>
                    </td>
                    <td>
                        <nz-select [(ngModel)]="selectEstadoContacto"  nzPlaceHolder="Cambiar Estado" (ngModelChange)="estadoContacto($event,selectEstadoContacto, data.id_contacto)" class="editar-transac">
                            <nz-option nzValue="1" nzLabel="Atendido"></nz-option>
                            <nz-option nzValue="2" nzLabel="En Espera"></nz-option>
                          </nz-select>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-row>
    
</nz-row>
