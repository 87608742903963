<nz-row class="survey-satisfaccion">
    <h3 class="color-kaizen text-msj">Encuesta de Satisfación</h3>
    <nz-col nzSpan="10" nzOffset="7">
        <form nz-form [formGroup]="validateForm" [nzLayout]="'vertical'">
            <nz-form-item class="items-block">
                <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="userRef" class="label-quest">Usuario</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="No seleccionó datos!">
                    <nz-input-group>
                        <input nz-input formControlName="userRef" placeholder="Nombre o Teléfono" [(ngModel)]="valueuserRef" />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item class="items-block">
                <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="resp1" class="label-quest">1.En general ¿Cuál fue tu grado de satisfacción con la compra en nuestra tienda online?</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="The input is not valid E-mail!">
                    <nz-rate [(ngModel)]="value" [nzTooltips]="tooltips" formControlName="resp1"></nz-rate>
                    <span *ngIf="value" class="ant-rate-text">{{ value ? tooltips[value - 1] : '' }}</span>
                    <!--<nz-radio-group formControlName="resp1" class="opc-quest">
                        <label nz-radio nzValue="1">1</label>
                        <label nz-radio nzValue="2">2</label>
                        <label nz-radio nzValue="3">3</label>
                        <label nz-radio nzValue="4">4</label>
                        <label nz-radio nzValue="5">5</label>
                    </nz-radio-group>-->
                </nz-form-control>
            </nz-form-item>
            <nz-form-item class="items-block">
                <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="resp2" class="label-quest">2.¿Dónde te encontrase con nuestra tienda online por primera vez?</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="The input is not valid E-mail!">
                    <nz-radio-group formControlName="resp2" [(ngModel)]="valueResp2" class="opc-quest_inline">
                        <label nz-radio nzValue="1">Amigo o familiar</label>
                        <label nz-radio nzValue="2">Banner publicitario</label>
                        <label nz-radio nzValue="3">Buscador (Google, Yahoo, etc.)</label>
                        <label nz-radio nzValue="4">Redes Sociales (Facebook, instagram)</label>
                        <label nz-radio nzValue="5">Correo</label>
                        <label nz-radio nzValue="6">Otro</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item class="items-block">
                <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="resp3" class="label-quest">3.¿Qué es lo que podemos hacer para mejorar tu satisfacción con la compra en nuestra tienda online?</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="No seleccionó datos!">
                    <textarea formControlName="resp3" [(ngModel)]="valueResp3" nz-input rows="4" placeholder="Escribe tu comentario..."></textarea>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item class="items-block">              
                <a class="back-kaizen btn-send" (click)="submitForm()">Enviar</a>
            </nz-form-item>
        </form>
    </nz-col>
</nz-row>
