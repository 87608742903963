<nz-row class="add-repuestos">
    <h2 class="color-kaizen title-contact">Agregar Repuesto</h2>
    <nz-col [nzXs]="{span:20, offset:2 }"  [nzMd]="{ span: 20, offset:2 }" [nzLg]="{ span: 20, offset:2 }">
        <form nz-form [formGroup]="validateForm" class="formularioAccount" (ngSubmit)="submitForm()">
            <nz-row>
                <!-- Nro de Parte -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="nroparte" class="lbl"><span class="lbl-form color-kaizen">Nro. de Parte</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="nroparte" placeholder="Ej: 1234567" nzTitle="Por Favor ingrese el número de parte" [(ngModel)]="input_nroparte" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('nroparte').dirty && validateForm.get('nroparte').errors">Por Favor ingrese su número de documento!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-- Marca del repuesto -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11, offset:1 }" [nzLg]="{ span: 7, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="marca_repuesto" class="lbl"><span class="lbl-form color-kaizen">Marca del repuesto</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="marca_repuesto" nzPlaceHolder="Selecciona la marca del repuesto" [(ngModel)]="selectmarcarepuesto" (ngModelChange)="obtenerDataModelo(selectmarcarepuesto)">
                                <nz-option *ngFor="let data of marcaDatos" [nzLabel]="data.nombre_marca_repuesto" [nzValue]="data.id_marca_repuesto"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11, offset:1 }" [nzLg]="{ span: 3, offset:0 }">
                    <a class="add-btn" (click)="showModal('marca')"> <i nz-icon nzType="plus" nzTheme="outline"></i> Nueva Marca</a>
                </div>
                
                <nz-modal
                [(nzVisible)]="isVisibleMarca"
                [nzTitle]="modalTitleMarca"
                [nzContent]="modalContentMarca"
                [nzFooter]="modalFooterMarca"
                (nzOnCancel)="handleCancel('marca')"
                >
                    <ng-template #modalTitleMarca>
                        <div class="modal-add_title">Agregar Marca</div>
                    </ng-template>

                    <ng-template #modalContentMarca>
                        <form nz-form [formGroup]="validateFormMarca" class="marca-form" (ngSubmit)="submitFormMarca()">
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Por favor ingresa la nueva marca!">
                                <nz-input-group >
                                  <input type="text" nz-input formControlName="nuevamarca" placeholder="Ingresa la marca" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                          </form>
                    </ng-template>

                    <ng-template #modalFooterMarca>
                        <button nz-button nzType="default" class="btn-add" (click)="handleOk('marca')">Agregar Marca</button>
                    </ng-template>
                </nz-modal>

                <!-- Nombre de repuesto -->
                <div nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11  }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="nombre_repuesto" class="lbl"><span class="lbl-form color-kaizen">Nombre de repuesto</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="nombre_repuesto" placeholder="Nombre repuesto" nzTitle="Nombre repuesto" [(ngModel)]="input_nombre" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('nombre_repuesto').dirty && validateForm.get('nombre_repuesto').errors">Por Favor ingrese el nombre del repuesto!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-- Tipo de repuesto -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 7, offset:1 }" [nzLg]="{ span: 10, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="tipo_repuesto" class="lbl"><span class="lbl-form color-kaizen">Tipo del repuesto</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="tipo_repuesto" nzPlaceHolder="Selecciona un tipo de repuesto" [(ngModel)]="selecttiporepuesto">
                                <nz-option *ngFor="let data of tiposDatos" [nzLabel]="data.nombre_tipo_repuesto" [nzValue]="data.id_tipo_repuesto" ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!--<div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 3, offset:0 }" [nzLg]="{ span: 3, offset:0 }">
                    <a class="add-btn" (click)="showModal('tipo')"> <i nz-icon nzType="plus" nzTheme="outline"></i> Nuevo Tipo</a>
                </div>-->

                <nz-modal
                [(nzVisible)]="isVisibleTipo"
                [nzTitle]="modalTitleTipo"
                [nzContent]="modalContentTipo"
                [nzFooter]="modalFooterTipo"
                (nzOnCancel)="handleCancel('tipo')"
                >
                    <ng-template #modalTitleTipo>
                        <div class="modal-add_title">Agregar Tipo</div>
                    </ng-template>

                    <ng-template #modalContentTipo>
                        <form nz-form [formGroup]="validateFormTipo" class="marca-form" (ngSubmit)="submitFormTipo()">
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Por favor ingresa el nuevo tipo!">
                                <nz-input-group >
                                  <input type="text" nz-input formControlName="nuevotipo" placeholder="Ingresa el tipo" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                          </form>
                    </ng-template>

                    <ng-template #modalFooterTipo>
                        <button nz-button nzType="default" class="btn-add" (click)="handleOk('tipo')">Agregar Tipo</button>
                    </ng-template>
                </nz-modal>

                <!-- Precio -->
                <div nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="precio" class="lbl"><span class="lbl-form color-kaizen">Precio</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="precio" placeholder="Precio" nzTitle="Por Favor ingrese su precio" [(ngModel)]="input_precio" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('precio').dirty && validateForm.get('precio').errors">Por Favor ingrese el precio!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-- Modelo de vehículo -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 7, offset:1 }" [nzLg]="{ span: 7, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="modelo_vehiculo" class="lbl"><span class="lbl-form color-kaizen">Modelo del vehículo</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="modelo_vehiculo" nzPlaceHolder="Selecciona un modelo de vehiculo" [(ngModel)]="selectmodelovehiculo">
                                <nz-option *ngFor="let data of modelosDatos" [nzLabel]="data.nombre_modelo" [nzValue]="data.id_modelo"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 3, offset:0 }" [nzLg]="{ span: 3, offset:0 }">
                    <a class="add-btn" (click)="showModal('modelo')"> <i nz-icon nzType="plus" nzTheme="outline"></i> Nuevo Modelo</a>
                </div>
                
                <!-- AGREGAR MODELO -->
                <nz-modal
                [(nzVisible)]="isVisibleModelo"
                [nzTitle]="modalTitleModelo"
                [nzContent]="modalContentModelo"
                [nzFooter]="modalFooterModelo"
                (nzOnCancel)="handleCancel('modelo')"
                >
                    <ng-template #modalTitleModelo>
                        <div class="modal-add_title">Agregar Modelo</div>
                    </ng-template>

                    <ng-template #modalContentModelo>
                        <form nz-form [formGroup]="validateFormModelo" class="marca-form" (ngSubmit)="submitFormModelo()">
                            <nz-form-item class="items-personalize">
                                <nz-form-label [nzRequired]="false" nzFor="add_marca_modelo" class="lbl"><span class="lbl-form color-kaizen">Marca</span></nz-form-label>
                                <nz-form-control>
                                    <nz-select nzShowSearch formControlName="add_marca_modelo" nzPlaceHolder="Selecciona una marca" [(ngModel)]="selectaddmarcamodelo">
                                        <nz-option *ngFor="let data of marcaVehiculosDatos" [nzLabel]="data.nombre_marca" [nzValue]="data.id_marca_vehiculo"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Por favor ingresa el nuevo modelo!">
                                <nz-input-group >
                                  <input type="text" nz-input formControlName="nuevomodelo" placeholder="Ingresa el modelo" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                          </form>
                    </ng-template>

                    <ng-template #modalFooterModelo>
                        <button nz-button nzType="default" class="btn-add" (click)="handleOk('modelo')">Agregar Modelo</button>
                    </ng-template>
                </nz-modal>


                <!-- Stock -->
                <div nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 11  }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="stock" class="lbl"><span class="lbl-form color-kaizen">Stock</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="stock" placeholder="Stock" nzTitle="Por Favor ingrese su stock" [(ngModel)]="input_stock" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('stock').dirty && validateForm.get('stock').errors">Por Favor ingrese el stock!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-- Repuesto alternativo -->
                <div nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 12, offset:1  }" [nzLg]="{ span: 11, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="repuestoalternativo" class="lbl"><span class="lbl-form color-kaizen">Repuesto alternativo</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="repuestoalternativo" placeholder="Repuesto alternativo" nzTitle="Por Favor ingrese su repuesto alternativo" [(ngModel)]="input_repuestoalternativo" nzPlacement="rightTop" nz-tooltip>
                            <nz-form-explain *ngIf="validateForm.get('repuestoalternativo').dirty && validateForm.get('repuestoalternativo').errors">Por Favor ingrese el stock!</nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                
                <nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 11  }" [nzLg]="{ span: 11 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Galeria del repuesto</span></nz-form-label>
                        <nz-form-control>
                            <input type="file" id="upload-foto" accept=".jpg" multiple (change)="subirFoto($event,'galeria')" style="display: none;" />
                                    <a class="add-btn" (click)="clickSubirFoto('galeria')">
                                        Subir imagenes
                                    </a>
                                    <div id="padre-canvas-foto">
                                       
                                    </div>
                                
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
                <nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 12, offset:1  }" [nzLg]="{ span: 5, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Imagen Predeterminada</span></nz-form-label>
                        <nz-form-control>
                            <input type="file" id="upload-foto_predeterminada" accept=".jpg" (change)="subirFoto($event, 'predeterminada')" style="display: none;" />
                                    <a class="add-btn" (click)="clickSubirFoto('predeterminada')">
                                        Subir foto
                                    </a>
                                    <div id="padre-canvas-foto_predeterminada">
                                       
                                    </div>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
                <nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 12, offset:1  }" [nzLg]="{ span: 6, offset:1 }">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" nzFor="epc" class="lbl"><span class="lbl-form color-kaizen">Imagen EPC</span></nz-form-label>
                        <nz-form-control>
                            <input type="file" id="upload-foto_epc" accept=".jpg" (change)="subirFoto($event, 'epc')" style="display: none;" />
                                    <a class="add-btn" (click)="clickSubirFoto('epc')">
                                        Subir foto
                                    </a>
                                    <div id="padre-canvas-foto_epc">
                                       
                                    </div>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>

                <!--Boton Enviar-->
                <div nz-col nzSpan="24">                        
                    <div nz-col [nzXs]="23" [nzSm]="{span:12, offset:6}" style="text-align:center;">
                        <a class="btn-send back-kaizen" id="enviar" (click)="submitForm()">Guardar Repuesto</a>
                    </div>
                </div>
            </nz-row>          
        </form>
    </nz-col>
</nz-row>