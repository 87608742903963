import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/services/survey.service';


@Component({
  selector: 'app-survey-servicios',
  templateUrl: './survey-servicios.component.html',
  styleUrls: ['./survey-servicios.component.css']
})
export class SurveyServiciosComponent implements OnInit {
  titulo;
  validateForm: FormGroup;
  tooltips = ['Muy mala', 'Mala', 'Normal', 'Buena', 'Excelente'];
  value = 0;
  valueResp2;
  valueResp3;
  valueuserRef;
  tituloEncuesta;
  preguntas;
  
  constructor(private fb: FormBuilder, private survey: SurveyService, 
    private router: Router, private message: NzNotificationService) {
      this.titulo = 'Kaizen Motors | Encuesta de Satisfaccion de Compra';
  }

  ngOnInit() {
      this.validateForm = this.fb.group({
        userRef: [null, Validators.required],
        resp1: [null, Validators.required],
        resp2: [null, Validators.required],
        resp3: [null, Validators.required]
      });

      this.survey.getEncuestaRepuestos().pipe(first()).subscribe(
        dataSurvey => {
          this.tituloEncuesta = dataSurvey.nombre_encuesta;
          this.preguntas = dataSurvey.preguntas;
          console.log(this.preguntas)
        }, error =>  {

        }
      );
  }

  submitForm(): void {

    const datosFormulario = this.validateForm.value;
    
    const encuestaDatos = [
      {
        id_pregunta: 1,
        respuesta: datosFormulario.resp1,
      },
      {
        id_pregunta: 2,
        respuesta: datosFormulario.resp2,
      },
      {
        id_pregunta: 3,
        respuesta: datosFormulario.resp3,
      }
    ]; 
    
    const datos = {
      id_encuesta: 1,
      referencia_cliente: this.valueuserRef,
      encuesta: encuestaDatos
    };

    console.log(datos);

    this.survey.enviarEncuestaRepuestos(datos).pipe(first()).subscribe(data => {
      // tslint:disable-next-line
      this.message.success('KAIZEN MOTORS', 'Encuesta registrada!');
      setTimeout(() => {
        this.router.navigate(['/home']);
      }, 200);
    }, error => {
      this.message.error('KAIZEN MOTORS', 'Ha ocurrido un error , por favor intente nuevamente');
    });
  }

}
