import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import format from 'date-fns/format';
import { BolsaTrabajoService } from '../services/bolsa-trabajo.service';

@Component({
  selector: 'app-bolsa-trabajo-oferta',
  templateUrl: './bolsa-trabajo-oferta.component.html',
  styleUrls: ['./bolsa-trabajo-oferta.component.css']
})
export class BolsaTrabajoOfertaComponent implements OnInit {
  validateForm: FormGroup;
  validateFormArea: FormGroup;
  validateFormObjetivo: FormGroup;
  validateFormRequisito: FormGroup;
  validateFormCompetencia: FormGroup;
  areaData;
  sedeData;
  objetivosData: FormGroup;
  requisitosData: FormGroup;
  competenciasData: FormGroup;

  selectarea;
  selectsede;
  selectobjetivo;
  selectrequisitos;
  competencias;
  fechaCaducidad;
  objInput:any;

  verArea;
  verCompetencia;

  listOfControl: Array<{ id: number; controlInstance: string}> = [];
  listOfControlRequisito: Array<{ id: number; controlInstance: string }> = [];
  listOfControlCompetencia: Array<{ id: number; controlInstance: string }> = [];

  constructor(private bolsaService: BolsaTrabajoService, private fb: FormBuilder) {
    this.validateForm = new FormGroup({
      cargo: new FormControl('', [Validators.required]),
      area: new FormControl('', [Validators.required]),
      sede: new FormControl('', [Validators.required]),
      objetivos: new FormControl( '', [ Validators.required ] ),
      requisitos: new FormControl( '', [ Validators.required ] ),
      competencias: new FormControl( '', [ Validators.required ] ),
      que_ofrecemos: new FormControl( '', [ Validators.required ] ),
      fecha_caducidad: new FormControl( '', [ Validators.required ] )
    });
    this.validateFormArea = new FormGroup({
      new: new FormControl('', [Validators.required])
    });
    
    this.verArea= false;
    this.verCompetencia= false;

  }

  ngOnInit(): void {
    this.validateFormObjetivo = this.fb.group({});
    this.validateFormRequisito = this.fb.group({});
    this.validateFormCompetencia = this.fb.group({});
    this.addField();
    this.addFieldCompetencia();
    this.addFieldRequisito();
    this.obtenerArea();
    this.obtenerSede();
    this.obtenerCompetencias();
  }

  obtenerArea() {
    this.bolsaService.obtenerAreas().subscribe( data => {
      this.areaData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerSede() {
    this.bolsaService.obtenerSede().subscribe( data => {
      this.sedeData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerObjetivo() {
    this.bolsaService.obtenerObjetivos().subscribe( data => {
      this.objetivosData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerRequisitos() {
    this.bolsaService.obtenerRequisitos().subscribe( data => {
      this.requisitosData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerCompetencias() {
    this.bolsaService.obtenerCompetencias().subscribe( data => {
      this.competenciasData = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  enviarOferta() {
    const datosFormulario = this.validateForm.value;
    const objetivos = this.validateFormObjetivo.value;
    const requisitos = this.validateFormRequisito.value;
    const competencias = this.validateForm.value.competencias;

    let datosObjetivos = Object.keys(objetivos).map(function (key) {
      return {
        posicion:key, 
        descripcion:objetivos[key]
      };
    });

    let datosRequisitos = Object.keys(requisitos).map(function (key) {
      return {
        posicion:key, 
        descripcion:requisitos[key]
      };
    });

    let datosCompetencias = Object.keys(competencias).map(function (key) {
      return {
        posicion:key, 
        id_competencia:competencias[key]
      };
    });

    let datos = {};
    datos = {
      cargo:this.validateForm.value.cargo,
      id_area_funcional:this.validateForm.value.area,
      id_departamento:this.validateForm.value.sede,
      fecha_caducidad:format(this.validateForm.value.fecha_caducidad, 'yyyy-MM-dd'),
      competencia:datosCompetencias,
      objetivo_empleo:datosObjetivos,
      detalle_requisito_empleo: datosRequisitos
    }
    console.log(datos);
    this.bolsaService.guardarOferta(datos).subscribe( result => {
      if (result!=null) {
        alert("Guardado conrrectamente")
        this.obtenerArea();
      } else {
        alert("No se pudo guardar");
      }
    });
  }

  guardarArea(){
    const datosFormulario = this.validateFormArea.value;
    let datos = {};
    datos = {
      nombre_area_funcional: datosFormulario.new,
      }
      this.bolsaService.guardarArea(datos).subscribe( result => {
        if (result!=null) {
          alert("Guardado conrrectamente")
          this.obtenerArea();
          this.verArea = false;
        } else {
          alert("No se pudo guardar");
        }
    });
  }

  guardarObjetivo(){
    const datosFormulario = this.validateFormObjetivo.value;
    let datos = {};
    datos = {
      nombre_objetivo_cargo: datosFormulario.new,
      }
      this.bolsaService.guardarObjetivos(datos).subscribe( result => {
        if (result!=null) {
          alert("Guardado conrrectamente")
          this.obtenerObjetivo();
        } else {
          alert("No se pudo guardar");
        }
    });
  }

  guardarRequisito(){
    const datosFormulario = this.validateFormRequisito.value;
    let datos = {};
    datos = {
      nombre_requisito: datosFormulario.new,
      }
      this.bolsaService.guardarRequisitos(datos).subscribe( result => {
        if (result!=null) {
          alert("Guardado conrrectamente")
          this.obtenerRequisitos();
        } else {
          alert("No se pudo guardar");
        }
    });
  }

  guardarCompetencia(){
    const datosFormulario = this.validateFormCompetencia.value;
    let datos = {};
    datos = {
      nombre_competencia: datosFormulario.new,
      }
      this.bolsaService.guardarCompetencias(datos).subscribe( result => {
        if (result!=null) {
          alert("Guardado conrrectamente")
          this.obtenerCompetencias();
          this.verCompetencia = false;
        } else {
          alert("No se pudo guardar");
        }
    });
  }

  addField( e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const id = this.listOfControl.length > 0 ? this.listOfControl[this.listOfControl.length - 1].id + 1 : 0;

    const control = {
      id,
      controlInstance: `${id}`
    };
    const index = this.listOfControl.push(control);
    this.validateFormObjetivo.addControl(
      this.listOfControl[index - 1].controlInstance,
      new FormControl(null, Validators.required)
    );
  }

  removeField(i: { id: number; controlInstance: string}, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControl.length > 1) {
      const index = this.listOfControl.indexOf(i);
      this.listOfControl.splice(index, 1);
      this.validateForm.removeControl(i.controlInstance);
    }
  }

  addFieldRequisito(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const id = this.listOfControlRequisito.length > 0 ? this.listOfControlRequisito[this.listOfControlRequisito.length - 1].id + 1 : 0;

    const control = {
      id,
      controlInstance: `${id}`
    };
    const index = this.listOfControlRequisito.push(control);
    this.validateFormRequisito.addControl(
      this.listOfControlRequisito[index - 1].controlInstance,
      new FormControl(null, Validators.required)
    );
  }

  removeFieldRequisito(i: { id: number; controlInstance: string }, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControlRequisito.length > 1) {
      const index = this.listOfControlRequisito.indexOf(i);
      this.listOfControlRequisito.splice(index, 1);
      this.validateFormRequisito.removeControl(i.controlInstance);
    }
  }

  addFieldCompetencia(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const id = this.listOfControlCompetencia.length > 0 ? this.listOfControlCompetencia[this.listOfControlCompetencia.length - 1].id + 1 : 0;

    const control = {
      id,
      controlInstance: `${id}`
    };
    const index = this.listOfControlCompetencia.push(control);
    this.validateFormCompetencia.addControl(
      this.listOfControlCompetencia[index - 1].controlInstance,
      new FormControl(null, Validators.required)
    );
  }

  removeFieldCompetencia(i: { id: number; controlInstance: string }, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControlCompetencia.length > 1) {
      const index = this.listOfControlCompetencia.indexOf(i);
      this.listOfControlCompetencia.splice(index, 1);
      this.validateFormCompetencia.removeControl(i.controlInstance);
    }
  }

  verNuevaArea() {
    this.verArea = true;
  }
  verNuevaCompetencia() {
    this.verCompetencia = true;
  }
}
