<nz-row class="clientes">
    <nz-row>
      <a class="download" style="cursor: pointer" (click)="exportexcel()">
        <i nz-icon nzType="download" nzTheme="outline"></i> Descargar Reporte
      </a>
    </nz-row>
    <nz-row>
        <div class="btn-download">
            
            <h2 class="report-title"> Citas Programadas </h2>
            <div class="fecha-reporte"><span>( </span> {{fechaInicial | date: 'MMM d'}} - {{fechaFinal | date: 'MMM d'}}<span> )</span></div>
        </div>
    </nz-row>
    <nz-row>
        <div nz-col [nzXs]="{span:23}"  [nzMd]="{span: 11}" [nzLg]="{span: 11}" class="items-personalize">
            <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Fecha inicial</span></nz-form-label>
            <nz-date-picker nzDisabledTime name="fecha_inicial" [(ngModel)]="fechaInicial" (ngModelChange)="setFecha(fechaInicial, fechaFinal)" class="fecha"></nz-date-picker>
        </div>
        <div nz-col [nzXs]="{span:23}"  [nzMd]="{span: 11}" [nzLg]="{span: 11}" class="items-personalize">
            <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Fecha final</span></nz-form-label>
            <nz-date-picker nzDisabledTime name="fecha_final" [(ngModel)]="fechaFinal" (ngModelChange)="setFecha(fechaInicial, fechaFinal)" class="fecha"></nz-date-picker>
        </div>
    </nz-row>
    <div class="loading" *ngIf="loading">
      <nz-spin nzSimple></nz-spin>
    </div>
    <nz-row style="overflow-x:auto;" *ngIf="!loading">
        
        <nz-table #nzTable [nzData]="listOfDisplayData">
            <thead>
              <tr class="thead-top">
                <th colspan="4">Cita</th>
                <th colspan="2">Cliente</th>
                <th colspan="3">Vehículo</th>
                <th colspan="2">Servicio</th>
                <th colspan="2">Seguimiento</th>
                <th colspan="2">Estados</th>
              </tr>
              <tr class="thead">
                <th>Fecha</th>
                <th>Hora</th>
                <th>Preorden</th>
                <th>Canal</th>
                <th>Nombre Completo</th>
                <th>Teléfono</th>
                <th>Placa</th>
                <th>Modelo</th>
                <th>Kilometraje</th>
                <th>Descripción</th>
                <th>Comentario</th>
                <th>Usuario</th>
                <th>Origen</th>
                <th>Confirmada?</th>
                <th>Asistió?</th>
              </tr>
            </thead>
            <tbody *ngIf="cantidadCitas > 0">
              <ng-template ngFor let-data let-index="index" [ngForOf]="nzTable.data">
                <tr>
                    <td>{{data.fecha_cita | date: 'd MMM, y'}}</td>
                    <td>{{data.hora}}</td>
                    <td>{{data.nro_preorden}}</td>
                    <td>{{data.procedencia}}</td>
                    <td>{{data.nombre_completo}}</td>
                    <td>{{data.telefono}}</td>
                    <td>{{data.placa}}</td>
                    <td>{{data.modelo}}</td>
                    <td>{{data.kilometraje}}</td>
                    <td>{{data.servicio}}</td>
                    <td>{{data.comentario}}</td>
                    <td>{{data.id_usuario}}</td>
                    <td>{{data.nombre_origen_cita}}</td>
                    <td class="item-btn">
                        <div *ngIf="data.confirmacion_cita" class="txt-yes">
                            Si
                        </div>
                        <div *ngIf="!data.confirmacion_cita " class="txt-no">
                            <a class="edit-confirmacion" (click)="showModalConfirmar(data.id_cita, data.placa)">No</a>
                        </div> 
                    </td>
                    <td class="item-btn">
                        <div *ngIf="data.confirmacion_asistencia" class="txt-yes_asistencia">
                            <a class="edit-asistencia" (click)="editarAsistencia(data.id_cita, data.placa, data.confirmado)">si</a>
                        </div>
                        <div *ngIf="!data.confirmacion_asistencia" class="txt-no">
                            <a class="edit-asistencia" (click)="editarAsistencia(data.id_cita, data.placa, data.confirmado)">No</a>
                        </div> 
                    </td>
                </tr>
              </ng-template>
            </tbody>
            <tbody *ngIf="cantidadCitas == 0">
              <ng-template >
                No hay citas agendadas
              </ng-template>
            </tbody>
          </nz-table>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <div class="search-box">
            <input type="text" nz-input placeholder="Buscar por Titular" [(ngModel)]="searchValue" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
              Buscar
            </button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button>
          </div>
        </nz-dropdown-menu>
    </nz-row>  
    <div *ngIf="!loading">
      <h2 class="report-title" > Citas para Chaperio </h2>
      <div class="fecha-reporte"><span>( </span> {{fechaInicial | date: 'MMM d'}} - {{fechaFinal | date: 'MMM d'}}<span> )</span></div>
      <nz-row style="overflow-x:auto;">
        <nz-table #nzTable [nzData]="listOfDisplayDataChaperio">
            <thead>
              <tr class="thead-top">
                <th colspan="4">Cita</th>
                <th colspan="2">Cliente</th>
                <th colspan="3">Vehículo</th>
                <th colspan="2">Servicio</th>
                <th colspan="2">Seguimiento</th>
                <th colspan="2">Estados</th>
              </tr>
              <tr class="thead">
                <th>Fecha</th>
                <th>Hora</th>
                <th>Preorden</th>
                <th>Canal</th>
                <th>Nombre Completo</th>
                <th>Teléfono</th>
                <th>Placa</th>
                <th>Modelo</th>
                <th>Kilometraje</th>
                <th>Descripción</th>
                <th>Comentario</th>
                <th>Usuario</th>
                <th>Origen</th>
                <th>Confirmada?</th>
                <th>Asistió?</th>
              </tr>
            </thead>
            <tbody *ngIf="cantidadCitasChaperio > 0">
              <ng-template ngFor let-data let-index="index" [ngForOf]="nzTable.data">
                <tr>
                    <td>{{data.fecha_cita}}</td>
                    <td>{{data.hora}}</td>
                    <td>{{data.nro_preorden}}</td>
                    <td>{{data.procedencia}}</td>
                    <td>{{data.nombre_completo}}</td>
                    <td>{{data.telefono}}</td>
                    <td>{{data.placa}}</td>
                    <td>{{data.modelo}}</td>
                    <td>{{data.kilometraje}}</td>
                    <td>{{data.servicio}}</td>
                    <td>{{data.comentario}}</td>
                    <td>{{data.id_usuario}}</td>
                    <td>{{data.nombre_origen_cita}}</td>
                    <td class="item-btn">
                        <div *ngIf="data.confirmacion_cita" class="txt-yes">
                            Si
                        </div>
                        <div *ngIf="!data.confirmacion_cita " class="txt-no">
                            <a class="edit-confirmacion" (click)="showModalConfirmar(data.id_cita, data.placa)">No</a>
                        </div> 
                    </td>
                    <td class="item-btn">
                        <div *ngIf="data.confirmacion_asistencia" class="txt-yes_asistencia">
                            <a class="edit-asistencia" (click)="editarAsistencia(data.id_cita, data.placa, data.confirmado)">si</a>
                        </div>
                        <div *ngIf="!data.confirmacion_asistencia" class="txt-no">
                            <a class="edit-asistencia" (click)="editarAsistencia(data.id_cita, data.placa, data.confirmado)">No</a>
                        </div> 
                    </td>
                </tr>
              </ng-template>
            </tbody>
            <tbody *ngIf="cantidadCitasChaperio == 0">
              <ng-template >
                No hay citas agendadas
              </ng-template>
            </tbody>
          </nz-table>
      </nz-row> 
    </div>
     
</nz-row>

<!-- Tabla para descargar -->
<table id="excel-table">
  <thead>
    <tr>
      <th colspan="17">Rango de fecha: <span>( </span> {{fechaInicial}} ; {{fechaFinal}}<span> )</span></th>
    </tr>
    <tr>
      <th colspan="17">CONTROL DE PRE AGENDADO DE CITAS</th>
    </tr>
    <tr>
      <th colspan="5">Cita</th>
      <th colspan="2"> Cliente </th>
      <th colspan="3"> Vehículo </th>
      <th colspan="2"> Servicio </th>
      <th colspan="2"> Agenda de Cita </th>
      <th colspan="3"> Estados </th>
    </tr>
    <tr>
      <th>Fecha</th>
      <th>Hora</th>
      <th>Preorden</th>
      <th>Taller</th>
      <th>Canal</th>
      <th>Nombre Completo</th>
      <th>Telefono</th>
      <th>Placa</th>
      <th>Modelo</th>
      <th>kilometraje</th>
      <th>Descripcion</th>
      <th>Comentario</th>
      <th>Usuario</th>
      <th>Origen</th>
      <th>Asistió?</th>
      <th>Confirmada?</th>
      <th>Eliminar?</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let option of listOfDisplayData">
      <td>{{option['fecha_cita']}}</td>
      <td>{{option['hora']}} {{option['formato_hora']}}</td>
      <td>{{option['nro_preorden']}}</td>
      <td>{{option['cod_taller']}}</td>
      <td>{{option['procedencia']}}</td>
      <td>{{option['nombre_completo']}}</td>
      <td>{{option['telefono']}}</td>
      <td>{{option['placa']}}</td>
      <td>{{option['modelo']}}</td>
      <td>{{option['kilometraje']}}</td>
      <td>{{option['servicio']}}</td>
      <td>{{option['comentario']}}</td>
      <td>{{option['ejecutivo_nombre']}}</td>
      <td>{{option['nombre_origen_cita']}}</td>
      <td>
        <div *ngIf="option.confirmacion_asistencia">
          Si
        </div>
        <div *ngIf="!option.confirmacion_asistencia">
          No
        </div>
      </td>
      <td>
        <div *ngIf="option.confirmacion_cita">
          Si
        </div>
        <div *ngIf="!option.confirmacion_cita">
          No
        </div>
      </td>
    </tr>
    <tr>
      <th colspan="17">PRE AGENDADO PARA CHAPERIO</th>
    </tr>
    <tr>
      <th colspan="5">Cita</th>
      <th colspan="2"> Cliente </th>
      <th colspan="3"> Vehículo </th>
      <th colspan="2"> Servicio </th>
      <th colspan="2"> Agenda de Cita </th>
      <th colspan="3"> Estados </th>
    </tr>
    <tr>
      <th>Fecha</th>
      <th>Hora</th>
      <th>Preorden</th>
      <th>Taller</th>
      <th>Canal</th>
      <th>Nombre Completo</th>
      <th>Telefono</th>
      <th>Placa</th>
      <th>Modelo</th>
      <th>kilometraje</th>
      <th>Descripcion</th>
      <th>Comentario</th>
      <th>Usuario</th>
      <th>Origen</th>
      <th>Asistió?</th>
      <th>Confirmada?</th>
      <th>Eliminar?</th>
    </tr>
    <div *ngIf="listOfDisplayDataChaperio">
      <tr *ngFor="let option of listOfDisplayDataChaperio">
        <td>{{option['fecha_cita']}}</td>
        <td>{{option['hora']}} {{option['formato_hora']}}</td>
        <td>{{option['nro_preorden']}}</td>
        <td>{{option['cod_taller']}}</td>
        <td>{{option['procedencia']}}</td>
        <td>{{option['nombre_completo']}}</td>
        <td>{{option['telefono']}}</td>
        <td>{{option['placa']}}</td>
        <td>{{option['modelo']}}</td>
        <td>{{option['kilometraje']}}</td>
        <td>{{option['servicio']}}</td>
        <td>{{option['comentario']}}</td>
        <td>{{option['ejecutivo_nombre']}}</td>
        <td>{{option['nombre_origen_cita']}}</td>
        <td>
          <div *ngIf="option.confirmacion_asistencia">
            Si
          </div>
          <div *ngIf="!option.confirmacion_asistencia">
            No
          </div>
        </td>
        <td>
          <div *ngIf="option.confirmacion_cita">
            Si
          </div>
          <div *ngIf="!option.confirmacion_cita">
            No
          </div>
        </td>
      </tr>
    </div>
    <div *ngIf="!listOfDisplayDataChaperio">
      <tr>
        <td colspan="17">No hay citas agendadas</td>
      </tr>
    </div>
  </tbody>
</table>

