<nz-row class="survey-satisfaccion">
    <h3 class="color-kaizen text-msj">Encuesta de Satisfacción</h3>
    <nz-col nzSpan="10" nzOffset="7">
      <form nz-form [formGroup]="validateForm" [nzLayout]="'vertical'">
        <nz-form-item class="items-block">
            <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="userRef" class="label-quest">Usuario</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="No seleccionó datos!">
                <nz-input-group>
                    <input nz-input formControlName="userRef" placeholder="Nombre o Teléfono" [(ngModel)]="valueuserRef" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item class="items-block">
          <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="resp1" class="label-quest">1.¿Fue satisfactorio el trabajo realizado en su vehículo?</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="Seleccione una opción!">
            <nz-radio-group formControlName="resp1" class="opc-quest">
                <label nz-radio nzValue="si">Si</label>
                <label nz-radio nzValue="no">No</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="items-block">
          <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="resp2" class="label-quest">2.¿Le entregaron en la fecha prometida?</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="Seleccione una opción!">
            <nz-radio-group formControlName="resp2" class="opc-quest">
                <label nz-radio nzValue="si">Si</label>
                <label nz-radio nzValue="no">No</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="items-block">
          <nz-form-label [nzSm]="24" [nzXs]="24" [nzLg]="24" nzRequired nzFor="resp3" class="label-quest">3.¿Cree usted que el tiempo en el que se realizó el trabajo fue aceptable?</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" [nzLg]="24" nzErrorTip="Seleccione una opción!">
            <nz-radio-group formControlName="resp3" class="opc-quest">
                <label nz-radio nzValue="si">Si</label>
                <label nz-radio nzValue="no">No</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="items-block">              
          <a class="back-kaizen btn-send" (click)="submitForm()">Enviar</a>
        </nz-form-item>
      </form>

    </nz-col>
</nz-row>
