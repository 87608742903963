import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actualizacion',
  templateUrl: './actualizacion.component.html',
  styleUrls: ['./actualizacion.component.css']
})
export class ActualizacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
