<nz-layout class="" *ngIf="logueado">
    <nz-header class="dashboard-header home-dashboard">
      <div class="logo">
          <img src="../../assets/images/logos/logo-kaizen-motors-blanco.svg"/>
      </div>
      <ul nz-menu nzMode="horizontal" class="header-menu">
        <li nz-menu-item nzSelected (click)="verClientes()">Clientes</li>
        <li nz-menu-item (click)="verRepuestos()">Repuestos</li>
        <li nz-menu-item (click)="verCitasProgramadas()">Citas</li>
        <li nz-menu-item (click)="verContactoClientes()">Contacto Clientes</li>
        <li nz-menu-item (click)="logout()" style="float: right;font-weight: 700;">Salir</li>
      </ul>
      
    </nz-header>
    <nz-layout class="dashboard-layout home-dashboard">
      <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" nzWidth="200px" nzTheme="light">
        <ul nz-menu nzMode="inline" class="sider-menu" [nzInlineCollapsed]="isCollapsed">
          <li nz-submenu nzTitle="Clientes" nzIcon="user" >
            <ul>
              <li nz-menu-item (click)="verClientes()">Registrados</li>
              <li nz-menu-item (click)="verClientesTeros()">Teros actualización</li>
            </ul>
          </li>
          <li nz-submenu nzTitle="B2B" nzIcon="user" >
            <ul>
              <li nz-menu-item (click)="verB2BlistadoClientes()">Clientes</li>
              <li nz-menu-item (click)="verB2BregistroClientes()">Registro de clientes</li>
              <li nz-menu-item (click)="verB2BlistadoTransacciones()">Transacciones</li>
            </ul>
          </li>

          <li nz-submenu nzTitle="Repuestos" nzIcon="file-search">
            <ul>
              <li nz-menu-item (click)="verRepuestos()">Ver Repuestos</li>
              <li nz-menu-item (click)="verAgregarRepuestos()">Agregar Repuesto</li>
              <li nz-menu-item (click)="verAgregarCompatibilidad()">Agregar Compatibilidad</li>
              <li nz-menu-item (click)="verAgregarEquivalencia()">Agregar Equivalencia</li>
            </ul>
          </li>
          <li nz-submenu nzTitle="Transacciones" nzIcon="shopping">
            <ul>
              <li nz-menu-item (click)="verTransacciones()">Repuestos</li>
              <li nz-menu-item (click)="verServicios()">Servicios por Mostrador</li>
              <li nz-menu-item (click)="verRepuestosMostrador()">Repuestos por Mostrador</li>
            </ul>
          </li>        
          <li nz-submenu nzTitle="Citas" nzIcon="notification">
            <ul>
              <li nz-menu-item (click)="verAgregarCita()">Agendar Cita</li>
              <li nz-menu-item (click)="verCitasProgramadas()">Citas Programadas</li>
              <li nz-menu-item (click)="verCitasPasadas()">Resumen de citas</li>
            </ul>
          </li>
          <li nz-submenu nzTitle="Bolsa de Trabajo" nzIcon="shopping">
            <ul>
              <li nz-menu-item (click)="verAgregarOferta()">Agregar oferta</li>
              <li nz-menu-item (click)="verOfertas()">Ver ofertas</li>
              <li nz-menu-item (click)="verPostulaciones()">Ver postulaciones</li>
            </ul>
          </li>
          <li nz-menu-item (click)="verPagoServicios()">
              <i nz-icon nzType="shopping-cart"></i>
              <span>Qr por Mostrador</span>
          </li>
          <li nz-submenu nzTitle="Encuestas" nzIcon="question">
            <ul>
              <li nz-menu-item (click)="verSurveyRepuestos()">Repuestos</li>
              <li nz-menu-item (click)="verSurveyServicios()">Servicios</li>
            </ul>
          </li>
          <li nz-submenu nzTitle="Reportes encuestas" nzIcon="question">
            <ul>
              <li nz-menu-item (click)="verReporteEncuestaWeb()">Web</li>
              <li nz-menu-item (click)="verReporteEncuestaMostrador()">Mostrador</li>
            </ul>
          </li>
          <li nz-menu-item (click)="verBitacora()">
            <i nz-icon nzType="book"></i>
            <span>Bitácora</span>
          </li>
        </ul>
      </nz-sider>
      <nz-layout class="inner-layout">
        <nz-content class="dashboard-content">
            <app-clientes *ngIf="mostrarClientes"></app-clientes>
            <app-listado *ngIf="mostrarClientesTeros"></app-listado>
            <app-listado-transacciones *ngIf="mostrarTransacciones"></app-listado-transacciones>
            <app-listado-servicios *ngIf="mostrarServicios"></app-listado-servicios>
            <app-repuestos *ngIf="mostrarRepuestos"></app-repuestos>
            <app-citas-programadas *ngIf="mostrarCitas"></app-citas-programadas>
            <app-contacto *ngIf="mostrarContacto"></app-contacto>
            <app-add-repuestos *ngIf="mostrarAgregarRepuestos"></app-add-repuestos>
            <app-agregar-compatibilidad *ngIf="mostrarCompatibilidad"></app-agregar-compatibilidad>
            <app-add-equivalencia *ngIf="mostrarEquivalencia"></app-add-equivalencia>
            <app-pago-servicios *ngIf="mostrarPagoServicios"></app-pago-servicios>
            <app-survey-repuestos *ngIf="mostrarSurveyRepuestos"></app-survey-repuestos>
            <app-survey-servicios *ngIf="mostrarSurveyServicios"></app-survey-servicios>
            <app-bitacora *ngIf="mostrarBitacora"></app-bitacora>
            <app-encuestas-web *ngIf="mostrarReporteEncuestaWeb"></app-encuestas-web>
            <app-encuestas-mostrador *ngIf="mostrarReporteEncuestaMostrador"></app-encuestas-mostrador>
            <app-agendar  *ngIf="mostrarAgendar"></app-agendar>
            <app-bolsa-trabajo-oferta *ngIf="mostrarAgregarOferta"></app-bolsa-trabajo-oferta>
            <app-bolsa-trabajo-postulaciones *ngIf="mostrarVerPostulaciones"></app-bolsa-trabajo-postulaciones>
            <app-bolsa-trabajo-ver-ofertas *ngIf="mostrarVerOfertas"></app-bolsa-trabajo-ver-ofertas>
            <app-b2b-listado-clientes *ngIf="mostrarB2BlistadoClientes"></app-b2b-listado-clientes>
            <app-b2b-registro-clientes *ngIf="mostrarB2BregistroClientes"></app-b2b-registro-clientes>
            <app-b2b-listado-transacciones *ngIf="mostrarB2BlistadoTransacciones"></app-b2b-listado-transacciones>
        </nz-content>
      </nz-layout>
    </nz-layout>
    <div class="mobile-dashboard">
      Vista no disponible en este dispositivo
    </div>
</nz-layout>



<nz-row class="no-logued" *ngIf="!logueado">
  <div nz-col [nzXs]="{span:24, offset:0}" [nzMd]="{ span: 24, offset: 0 }" [nzLg]="{ span: 24, offset: 0 }" >
      <nz-row class="title-section">
          <h2 class="title-citas color-kaizen">Necesitas estar logueado para acceder a esta página</h2>
      </nz-row>
  </div>
</nz-row>