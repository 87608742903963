import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BolsaTrabajoService {

    token: string;
    
    constructor(private http: HttpClient) { 
      if (localStorage.getItem('userKaizenDashboard')) {
        this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
      }
    }

    // Servicio que obtiene las postulaciones
    obtenerPostulaciones() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/postulacion`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las ofertas
    obtenerOfertas() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/empleo`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las marcas de los repuestos
    obtenerSede() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/ciudad`;
      return this.http.get<any>(url, httpOptions);
    }

    obtenerAreas() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/area-funcional`;
      return this.http.get<any>(url, httpOptions);
    }
    obtenerCompetencias() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/competencia`;
      return this.http.get<any>(url, httpOptions);
    }
    obtenerObjetivos() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/objetivo-cargo`;
      return this.http.get<any>(url, httpOptions);
    }

    obtenerRequisitos() {
      const datos = {
        estado: 'actual'
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/requisito`;
      return this.http.get<any>(url, httpOptions);
    }

    // Guardar area
    guardarArea(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/area-funcional`;
      return this.http.post<any>(url,data, httpOptions);
    }
    // Guardar objetivos
    guardarObjetivos(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/objetivo-cargo`;
      return this.http.post<any>(url,data, httpOptions);
    }
    // Guardar requisitos
    guardarRequisitos(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/requisito`;
      return this.http.post<any>(url,data, httpOptions);
    }
    // Guardar competencias
    guardarCompetencias(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/competencia`;
      return this.http.post<any>(url,data, httpOptions);
    }

    // Guardar oferta
    guardarOferta(data) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/bolsa-trabajo/empleo`;
      return this.http.post<any>(url,data, httpOptions);
    }

}
