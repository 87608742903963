<nz-row class="add-repuestos">
    <h2 class="color-kaizen title-contact">Agendar Cita</h2>
    <nz-col [nzXs]="{span:20, offset:2 }"  [nzMd]="{ span: 20, offset:2 }" [nzLg]="{ span: 20, offset:2 }">
        <form nz-form  [formGroup]="validateForm"  class="formularioAccount">
            <nz-row>
                <!-- Marca -->
                <div nz-col [nzXs]="{span:23, offset:0}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11 }" class="first-column">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Qué marca agendará</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzPlaceHolder="Selecciona la marca " formControlName="marcaagendar"  [(ngModel)]="selectmarca" (ngModelChange)="obtenerModelos(selectmarca)">
                                <nz-option *ngFor="let data of marcasData" [nzLabel]="data.nombre_marca" [nzValue]="data.cod_marca"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Placa</span></nz-form-label>
                        <nz-form-control class="control-paralelo">
                            <input type="text" nz-input formControlName="placa_cita" placeholder="Placa" [(ngModel)]="input_placa" nzPlacement="rightTop" nz-tooltip>
                            <a class="btn-buscarcliente" (click)="buscarvehiculo()">Buscar</a>
                        </nz-form-control>
                        
                    </nz-form-item>

                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Nombre del contacto para cita</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="nombre" placeholder="Nombre del contacto para cita" [(ngModel)]="input_nombre" nzPlacement="rightTop" nz-tooltip>
                        </nz-form-control>
                    </nz-form-item>

                    <!--<nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Apellido del contacto para cita</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="apellido" placeholder="Apellido del contacto para cita" [(ngModel)]="input_apellido" nzPlacement="rightTop" nz-tooltip>
                        </nz-form-control>
                    </nz-form-item>-->

                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Teléfono</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input formControlName="telefono" placeholder="Teléfono" [(ngModel)]="input_telefono" nzPlacement="rightTop" nz-tooltip>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Origen</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearchnzPlaceHolder="Selecciona el origen " formControlName="origen_cita" [(ngModel)]="selectorigen">
                                <nz-option nzLabel="Llamada" nzValue="3"></nz-option>
                                <nz-option nzLabel="Mostrador" nzValue="4"></nz-option>
                                <nz-option nzLabel="WhatsApp" nzValue="2"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>


                <!-- Segunda columna -->
                <div nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 11 }" [nzLg]="{ span: 11  }" class="second-column">
                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Modelo comercial</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzPlaceHolder="Selecciona el modelo " formControlName="modelo_comercial" [(ngModel)]="selectmodelo">
                                <nz-option *ngFor="let data of modelosData" [nzLabel]="data.descripcion" [nzValue]="data.codigo"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-row>
                        <nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 12 }" [nzLg]="{ span: 12  }">
                            <div class="items-personalize">
                                <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Fecha</span></nz-form-label>
                                <nz-date-picker nzDisabledTime formControlName="fecha_cita" [(ngModel)]="fecha" (ngModelChange)="setFecha()" class="fecha"></nz-date-picker>
                            </div>
                        </nz-col>
                        <nz-col [nzXs]="{span:23}"  [nzMd]="{ span: 12 }" [nzLg]="{ span: 12  }">
                            <nz-form-item class="items-personalize">
                                <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Hora</span></nz-form-label>
                                <nz-form-control>
                                    <nz-select nzShowSearch formControlName="hora_cita" nzPlaceHolder="Selecciona la hora " [(ngModel)]="selecthora">
                                        <nz-option *ngFor="let data of horaData" [nzLabel]="data.hora" [nzValue]="data.id_horario"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </nz-col>
                    </nz-row>

                    <nz-form-item class="items-personalize">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Servicio</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="servicio_cita" nzPlaceHolder="Selecciona el servicio " [(ngModel)]="selectservicio"  (ngModelChange)="servicoseleccionado(selectservicio)">
                                <nz-option *ngFor="let data of serviciosData" [nzLabel]="data.servicio" [nzValue]="data.id_servicio"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="items-personalize" *ngIf="isMantenimineto">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Kilometraje</span></nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch formControlName="kilometraje_cita" nzPlaceHolder="Selecciona el kilometraje ">
                                <nz-option [nzLabel]="1000" [nzValue]="1000"></nz-option>
                                <nz-option *ngFor="let km of selectkilometraje" [nzLabel]="km" [nzValue]="km"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="items-personalize" *ngIf="!isMantenimineto">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Kilometraje</span></nz-form-label>
                        <nz-form-control>
                            <input type="text" nz-input  formControlName="kilometraje_abierto" placeholder="Kilometraje" [(ngModel)]="input_kilometraje" nzPlacement="rightTop" nz-tooltip>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="items-block">
                        <nz-form-label [nzRequired]="false" class="lbl"><span class="lbl-form color-kaizen">Comentario</span></nz-form-label>
                        <nz-form-control>
                            <textarea formControlName="comentario_cita" [(ngModel)]="input_comentario" nz-input rows="4" placeholder="Escribe tu comentario..."></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                
            </nz-row> 
            <nz-row>
                <a class="send" (click)="enviarCita()">Agendar</a>
            </nz-row>         
        </form>
    </nz-col>
</nz-row>