import { Component, OnInit } from '@angular/core';
import { ContactoService } from '../services/contacto.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  clientesDatos ;
  listOfDisplayData;
  selectEstadoContacto;

  constructor(private contactos: ContactoService) { }

  ngOnInit(): void {
    this.mostrarListadoContacto();
  }

  mostrarListadoContacto() {
    this.contactos.obtenerContactos().subscribe( data => {
      this.clientesDatos = data;
      this.listOfDisplayData = this.clientesDatos;
      console.log(this.clientesDatos);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  estadoContacto(e, estado, idContacto) {
    const datos = {
      id_contacto: idContacto,
      estado: estado,
      _method : 'PUT'
    };

    this.contactos.editarEstadoContactos(datos).subscribe( data => {
        console.log(data);   
        this.mostrarListadoContacto();  
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

}
