import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompatibilidadService } from 'src/app/services/repuestos/compatibilidad.service';

@Component({
  selector: 'app-agregar-compatibilidad',
  templateUrl: './agregar-compatibilidad.component.html',
  styleUrls: ['./agregar-compatibilidad.component.css']
})
export class AgregarCompatibilidadComponent implements OnInit {
  /* Form component */
  validateForm: FormGroup;
  validateFormMarca : FormGroup;
  validateFormModelo: FormGroup;
  validateFormMotor: FormGroup;

  listOfOption = [];
  listOfTagOptions = [];

  input_anio = '';
  selectmarca = 0;
  selectmotor = 0;
  selectmodelovehiculo = 0;
  selectaddmarca = 0;

  /* modal marca */
  isVisibleMarca;
  isVisibleModelo;
  isVisibleMotor;

  marcaDatos = [];
  modelosDatos = [];
  motorDatos = [];

  constructor(private fb: FormBuilder, private repuestos: CompatibilidadService) { 

    /* modal marca */
    this.isVisibleMarca = false;
    this.isVisibleModelo= false;
    this.isVisibleMotor= false;

    this.validateForm = this.fb.group({
      nroparte: [ null, [ Validators.required ] ],
      marca: [null, [Validators.required]],
      motor: [ null, [ Validators.required ] ],
      modelo_vehiculo: [ null, [ Validators.required ] ],
      anio: [ null, [ Validators.required ] ]
    });

    this.validateFormMarca = this.fb.group({
      nuevamarca: [ null, [ Validators.required ] ]
    });

    this.validateFormModelo = this.fb.group({
      add_marca_modelo: [ null, [ Validators.required ] ],
      nuevomodelo: [ null, [ Validators.required ] ]
    });

    this.validateFormMotor = this.fb.group({
      nromotor: [ null, [ Validators.required ] ],
      cilindrada: [ null, [ Validators.required ] ]
    });
  }

  ngOnInit(): void {

    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    //this.listOfOption = children;
    this.obtenerNroParte();
    this.obtenerDataMarcas();
    this.obtenerMotor();
  }

  obtenerNroParte() {
    this.repuestos.getNroParte().subscribe( data => {
      this.listOfOption = data;
      //console.log(this.listOfOption);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerDataMarcas() {
    this.repuestos.getMarcasVehiculos().subscribe( data => {
      this.marcaDatos = data;
      //console.log(this.marcaDatos);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
  obtenerMotor() {
    this.repuestos.getMotor().subscribe( data => {
      this.motorDatos = data;
      console.log(this.motorDatos);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerDataModelo(marcaID) {
    this.repuestos.getModelosVehiculos(marcaID).subscribe( data => {
      this.modelosDatos = data;
      //console.log(this.modelosDatos);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  submitForm(): void {
    // this.cartInfo = true;
    // this.formEdit = false;
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    // stop here if form is invalid
    if (this.validateForm.invalid) {
      return;
    }

    const datosFormulario = this.validateForm.value;
    const datos = {
      nombre: datosFormulario.nombres,
      marca_repuesto: datosFormulario.marca_repuesto,
      nombre_repuesto: parseInt(datosFormulario.nombre_repuesto, 10),
      tipo_repuesto: datosFormulario.tipo_repuesto,
      precio: 7,
      stock: 'web-app',
      cod_clase_clie: 1
    };
    
    console.log(datos);
  }

  submitFormMarca(): void {

  }
  submitFormModelo(): void {

  }
  submitFormMotor(): void {

  }


  /* Modals adicionales */
  showModal(id): void {
    if(id === 'marca'){
      this.isVisibleMarca = true;
    }
    if(id === 'modelo'){
      this.isVisibleModelo = true;
    }
    if(id === 'motor'){
      this.isVisibleMotor = true;
    }
  }

  handleOk(id): void {
    if(id='marca'){
      this.isVisibleMarca = false;
    }
    if(id='modelo'){
      this.isVisibleModelo = false;
    }
    if(id='motor'){
      this.isVisibleMotor = false;
    }
  }

  handleCancel(id): void {
    if(id='marca'){
      this.isVisibleMarca = false;
    }
    if(id='modelo'){
      this.isVisibleModelo = false;
    }
    if(id='motor'){
      this.isVisibleMotor = false;
    }
  }

}
