import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { AddrepuestosService } from 'src/app/services/repuestos/addrepuestos.service';

@Component({
  selector: 'app-add-repuestos',
  templateUrl: './add-repuestos.component.html',
  styleUrls: ['./add-repuestos.component.css']
})
export class AddRepuestosComponent implements OnInit {
  /* Form component */
  validateForm: FormGroup;
  validateFormMarca : FormGroup;
  validateFormModelo: FormGroup;
  validateFormTipo: FormGroup;
  validateFormCompatibilidad: FormGroup;
  validateFormEquivalencia: FormGroup;

  input_nroparte = '';
  input_nombre = '';
  input_precio = '';
  input_stock = '';
  input_repuestoalternativo = '';
  selectmarcarepuesto = 0;
  selecttiporepuesto = 0;
  selectmodelovehiculo = 0;


  selectaddmarcamodelo = 0;

  /* cargar imagen */
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };
  fileList = [];
  imagengaleria = [];
  imagenepc;
  imagenpredet;
  previewImage: string | undefined = '';
  previewVisible = false;

  /* modal marca */
  isVisibleMarca = false;
  isVisibleModelo= false;
  isVisibleTipo= false;

  marcaDatos = [];
  marcaVehiculosDatos = [];
  tiposDatos = [];
  modelosDatos = [];

  /* Chambonadas de Jeziel */
  formData: FormData;

  constructor(private fb: FormBuilder, private repuestos: AddrepuestosService, private message: NzMessageService) {

    /* modal marca */
    this.isVisibleMarca = false;
    this.isVisibleModelo= false;
    this.isVisibleTipo= false;

    this.fileList = [
      {
        uid: -1,
        name: 'repuesto.png',
        status: 'done',
        url: 'https://ws-kaizen-motors.kaizenmotors.com/images/repuestos/OE117J/OE117J-01.jpg'
      }
    ];

    this.validateForm = this.fb.group({
      nroparte: [ null, [ Validators.required ] ],
      marca_repuesto: [null, [Validators.required]],
      nombre_repuesto: [ null, [ Validators.required ] ],
      tipo_repuesto: [ null, [ Validators.required ] ],
      precio: [ null, [ Validators.required ] ],
      modelo_vehiculo: [ null, [ Validators.required ] ],
      stock: [ null, [ Validators.required ] ],
      repuestoalternativo: [ null, [ Validators.required ] ],
      galeria: [ null, [ Validators.required ] ],
      epc: [ null, [ Validators.required ] ],
      imagenpredeterminada: [ null, [ Validators.required ] ]
    });

    this.validateFormMarca = this.fb.group({
      nuevamarca: [ null, [ Validators.required ] ]
    });

    this.validateFormModelo = this.fb.group({
      add_marca_modelo: [ null, [ Validators.required ] ],
      nuevomodelo: [ null, [ Validators.required ] ]
    });

    this.validateFormTipo = this.fb.group({
      nuevotipo: [ null, [ Validators.required ] ]
    });

  }

  ngOnInit(): void {
    this.formData = new FormData();
    this.obtenerDataMarcas();
    this.obtenerDataTipo();
    this.obtenerDataMarcasVehiculos();
    this.obtenerDataModelo(this.selectmarcarepuesto);
  }

  obtenerDataMarcas() {
    this.repuestos.getMarcasRepuesto().subscribe( data => {
      this.marcaDatos = data;
      console.log(this.marcaDatos);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  obtenerDataMarcasVehiculos() {
    this.repuestos.getMarcasVehiculos().subscribe( data => {
      this.marcaVehiculosDatos = data;
      
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
  obtenerDataTipo() {
    this.repuestos.getTipoRepuesto().subscribe( data => {
      this.tiposDatos = data;
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }
  obtenerDataModelo(marcaID) {
    this.repuestos.getModelosRepuesto(marcaID).subscribe( data => {
      this.modelosDatos = data;
      this.obtenerDataMarcas();
      console.log(this.modelosDatos);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  submitForm(): void {

    const datosFormulario = this.validateForm.value;
    const datos = {
      numero_parte: datosFormulario.nroparte,
      nombre_repuesto: datosFormulario.nombre_repuesto,
      numero_parte_alternativo: datosFormulario.repuestoalternativo,
      stock: datosFormulario.stock,
      precio: datosFormulario.precio,
      imagen_repuesto: this.imagenpredet,
      imagen_epc: this.imagenepc,
      galeria:this.imagengaleria,
      id_marca_repuesto:datosFormulario.marca_repuesto,
      id_tipo_repuesto:datosFormulario.tipo_repuesto
    };

    this.formData.append('numero_parte', datosFormulario.nroparte.toString());
    this.formData.append('nombre_repuesto', datosFormulario.nombre_repuesto);
    this.formData.append('numero_parte_alternativo', datosFormulario.repuestoalternativo);
    this.formData.append('stock', datosFormulario.stock);
    this.formData.append('precio', datosFormulario.precio);
    this.formData.append('id_marca_repuesto', datosFormulario.marca_repuesto);
    this.formData.append('id_tipo_repuesto', datosFormulario.tipo_repuesto);
    if (this.imagengaleria.length > 0) {
      //this.formData.append('galeria', 'true');
      this.imagengaleria.map((archivo, index) => {
        this.formData.append(`galeria[${index}]`, archivo, archivo.name);
      });
    }   
    this.formData.append('imagen_epc', this.imagenepc);
    this.formData.append('imagen_repuesto', this.imagenpredet);

    this.repuestos.addRepuesto(this.formData).subscribe( data => {
      console.log(data);
      this.message.create('sucess', 'Repuesto Guardado!');
    }, error => {
      this.message.create('error', 'No se pudo guardar!');
    });
    
    console.log(datos);
  }

  submitFormMarca(): void {
    const datosFormulario = this.validateFormMarca.value;
    const datos = {
      nombre_marca_repuesto: datosFormulario.nuevamarca
    };
    
    console.log(datos);

    this.repuestos.addMarcaRepuesto(datos).subscribe( data => {
      this.message.create('sucess', 'Marca Guardada!');
      setTimeout(() => {
        this.obtenerDataMarcas();
      }, 500);
      
    }, error => {
        console.log(error); // Imprimimos en consola el error
        this.message.create('error', 'No se pudo guardar!');
    });
  }
  submitFormModelo(): void {
    const datosFormulario = this.validateFormModelo.value;
    const datos = {
      nombre_modelo: datosFormulario.nuevomodelo,
      id_marca_vehiculo: datosFormulario.add_marca_modelo
    };
    
    console.log(datos);

    this.repuestos.addModelo(datos).subscribe( data => {
      this.message.create('sucess', 'Modelo Guardado!');
      this.obtenerDataMarcas();
    }, error => {
        console.log(error); // Imprimimos en consola el error
        this.message.create('error', 'No se pudo guardar!');
    });
  }
  submitFormTipo(): void {

  }

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };

  /* Modals adicionales */
  showModal(id): void {
    if(id === 'marca'){
      this.isVisibleMarca = true;
    }
    if(id === 'modelo'){
      this.isVisibleModelo = true;
    }
    if(id === 'tipo'){
      this.isVisibleTipo = true;
    }
  }

  handleOk(id): void {
    if(id==='marca'){
      this.isVisibleMarca = false;
      this.submitFormMarca();
    }
    if(id==='modelo'){
      this.isVisibleModelo = false;
      this.submitFormModelo();
    }
    if(id==='tipo'){
      this.isVisibleTipo = false;
    }
  }

  handleCancel(id): void {
    if(id='marca'){
      this.isVisibleMarca = false;
    }
    if(id='modelo'){
      this.isVisibleModelo = false;
    }
    if(id='tipo'){
      this.isVisibleTipo = false;
    }
  }

  //subir foto galeria
  clickSubirFoto(elemento) {
      if(elemento === 'galeria') {
        document.getElementById('upload-foto').click();
      }
      if(elemento === 'predeterminada') {
        document.getElementById('upload-foto_predeterminada').click();
      }
      if(elemento === 'epc') {  
        document.getElementById('upload-foto_epc').click();
      }
  }

  subirFoto(e, elemento) {
      const obj = e.target.files
      const that = this;
      let cargar = (file) => {
          let padre ;

          if(elemento === 'galeria') {
            padre = document.getElementById('padre-canvas-foto');
          }
          if(elemento === 'predeterminada') {
            padre = document.getElementById('padre-canvas-foto_predeterminada');
          }
          if(elemento === 'epc') {  
            padre = document.getElementById('padre-canvas-foto_epc');
          }
          const reader = new FileReader();
      
          reader.onload = function(){
              
              const image = new Image();
              image.height = 100;
              image.title = file.name;
              image.src = ""+this.result;
              if(elemento === 'galeria') {
                //that.imagengaleria.push(this.result);
                that.imagengaleria.push(file);
              }
              if(elemento === 'predeterminada') {
                //that.imagenpredet = this.result;
                that.imagenpredet = file;
              }
              if(elemento === 'epc') {  
                that.imagenepc = file;
                //that.imagenepc = this.result;
              }
              

              padre.appendChild( image );
            };
            reader.readAsDataURL(file);
      }

      if (obj) {
        [].forEach.call(obj, cargar);
    }
  }
}
