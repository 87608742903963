import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes.service';

@Component({
  selector: 'app-b2b-listado-clientes',
  templateUrl: './b2b-listado-clientes.component.html',
  styleUrls: ['./b2b-listado-clientes.component.css']
})
export class B2bListadoClientesComponent implements OnInit {
/*name of the excel-file which will be downloaded. */ 
reporteEncuestas= 'Clientes-Kaizen.xlsx'; 
listOfDisplayData:any=[];
dataClientes;
/* Info to Map */
latitude: number=0;
longitude: number=0;
zoom: number=0;
address: string="";
private geoCoder:any;
/* Modal de mapa */
isVisible = false;

@ViewChild('search') public searchElementRef!: ElementRef;

nombres:any;
apellidos:any;

pageIndex:any;
total:any;
pageSize:any;
pageSizeOptions:any;

constructor(private clientes: ClientesService) { 
  //this.listarCliente();
  this.pageIndex = 1;
  this.pageSize = 10;

}

listarCliente() {
  this.clientes.listarClienteB2B().subscribe( data => {
    this.dataClientes = data;
    this.total = data.length;
    for (let i = 0; i < data.length; i++) {
      this.dataClientes = data;
      this.listOfDisplayData.push(
        {
          edit: false,
          data: data[i]
        }
      );

    }
  }, error => {
      console.log(error); // Imprimimos en consola el error
  });
}

ngOnInit(): void {
  this.clientes.listarClienteB2B().subscribe( data => {
    this.dataClientes = data;
    for (let i = 0; i < data.length; i++) {
      this.listOfDisplayData.push(
        {
          edit: false,
          data: data[i]
        }
      );

    }
  }, error => {
      console.log(error); // Imprimimos en consola el error
  });
}


  startEdit(id: string): void {
    this.listOfDisplayData[id].edit = true;
    this.nombres = this.listOfDisplayData[id].data.nombre_completo;
    this.apellidos = this.listOfDisplayData[id].data.nombre_completo;
  }

  canceltEdit(id: string): void {
    this.listOfDisplayData[id].edit = false;
  }
  
  abrirMapa(id:any):void {
    this.isVisible = true;
  }
  actualizar(i, ci,completo,telefono,correo,direccion): void {
    if((completo!=""||completo==null) && correo!="" && direccion!="" && this.latitude !=null) {
      let datos = {};
      datos = {
        ci_nit:ci,
        nombre_completo:completo,
        //nombre:this.nombres,
        //apellidos:this.apellidos,
        direccion:direccion,
        email:correo,
        telefono:telefono,
        latitud:this.latitude,
        longitud:this.longitude,
        _method:'PUT'
        }

        this.clientes.actualizarClientesTeros(datos).subscribe( result => {
          if (result!=null) {
            alert("Cliente actualizado");
            this.canceltEdit(i);
            this.ngOnInit();
          } else {
            alert("No se pudo actualizar, intente más tarde.");
          }
        }, error => alert("No se pudo actualizar.")
        );

    } else {
      alert("ERROR!!, Debe registrar todos los campos e indicar la dirección con el ping del mapa");
    }
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  ngAfterViewInit() {
    
  }

  pageIndexChanged($event: any): void {
    console.log($event);
  }
  pageSizeChanged($event: any): void {
    console.log($event);
  }
}
