import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  token: string;
  constructor(private http: HttpClient) { 
    if (localStorage.getItem('userKaizenDashboard')) {
      this.token = JSON.parse(localStorage.getItem('userKaizenDashboard')).token;
    }
  }

  // Servicio que obtiene las marcas de los repuestos
  obtenerContactos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/contactenos`;
    return this.http.get<any>(url, httpOptions);
  }

  // Edicion del estado de transacciones
  editarEstadoContactos(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/contactenos`;
    return this.http.post<any>(url,data, httpOptions);
  }

}
